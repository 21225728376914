<template>
  <div class="sme" v-bind:class="[item.className, { active: activePage===index || $route?.path ===item.link }]" v-bind:data-index=index>
    <router-link v-bind:to="item.link">
      <div class="smei"><svg-injector :class-name="'js-svg-injector'" v-bind:src="item.img"></svg-injector></div>
      <!--<img class="injectit" v-bind:src="item.img" >-->
      <div class="smet">{{item.name}}</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ItemMenu',
  props: ['item','activePage', 'index', 'stakePage', 'mobMenu'],
}
</script>
