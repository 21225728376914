<template>
    <div v-if="pool?.names" :class="'enreach-row '+(pool.pool?'':'esfrcs')+(pool.dev?' isdev':'')" @click="pool.pool?this.$router.push({ name: 'pair', params: { master_page: master_page, pair: pool.id } }):false">
        <div>
            <div :class="'rowcolorcode rcc'+((index%4)+1)"></div>
            <div class="rowcoincombo">
                <div class="rcombo1" v-if="!pool?.oneIcon"><TokenIcon :token="pool.names[0]" /></div>
                <div class="rcombo2"><TokenIcon :token="pool.names[1]" /></div>
            </div>
            <div class="rowtext">{{pool.names[0]}}</div>
            <div class="rowarrows"><svg-injector :class-name="'js-svg-injector'" src="/img/rowarrows.svg"></svg-injector></div>
            <div class="rowtext">{{pool.names[1]}}</div>
        </div>
        <div>{{displayPoolAPY}}</div>
        <div>{{displayPoolBoostAPY}}</div>
    </div>
</template>
<script>
import TokenIcon from "@/components/TokenIcon";
import { mapGetters } from "vuex";
export default {
  props: {
    pool: {
      type: Object,
      required: true,
    },
    master_page: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false
    },
  },
  components: {
    TokenIcon,
  },
  beforeUnmount() {
  },
  data() {
      return {
        poolFee:'--',
        poolTotal:0
      }
  },
  methods: {
    poolInfos(){
      let nrchAPI = this.getNrchAPI;
      let pool = this.pool;
      let contract = pool?.contract;
      let pool_address = pool?.pool_address;
      let chain_id = pool?.activeChain;
      if(contract in nrchAPI && chain_id in nrchAPI[contract] && pool_address in nrchAPI[contract][chain_id]){
        return nrchAPI[contract][chain_id][pool_address];
      }
      return {};
    }
  },
  computed:{
    ...mapGetters(["getNrchAPI"]),
    displayPoolAPY(){
      const infos = this.poolInfos();
      if(infos){
        let apy = infos?.APY?Number(infos.APY).toFixed(2):0;
        // pool.BOOSTAPY = infos?.BOOSTAPY?Number(infos.BOOSTAPY).toFixed(2):0;
        return apy+'%';
      }
      return '--';
    },
    displayPoolBoostAPY(){
      const infos = this.poolInfos();
      if(infos){
        let apy = infos?.BOOSTAPY?Number(infos.BOOSTAPY).toFixed(2):0;
        return apy+'%';
      }
      return '--';
    },
  }    
}
</script>