import YgToken from "../../contracts/YG4626.json";

const state = {
  num: 0,
  deposited: 0,
  approvedBalance: 0,
  tokenAddress: null,
  tokenContract: null,
  tokenInfos: null,
  ygTokenAddress: null,
  ygTokenContract: null,
  ygTokenInfos: null,
  ygTokenApy: null,
  ygTokenBalance: null,
  ygTokenAssetsOf: null,
  lockPeriod: 0,
  lockedTimestamp: 0
};

const getters = {
  getNum: (state) => state.num,
  getDeposited: (state) => state.deposited,
  getTokenAddress: (state) => state.tokenAddress,
  getTokenInfos: (state) => state.tokenInfos,
  getYgTokenAddress: (state) => state.ygTokenAddress,
  getYgTokenInfos: (state) => state.ygTokenInfos,
  getYgTokenApy: (state) => state.ygTokenApy,
  getYgTokenBalance: (state) => state.ygTokenBalance,
  getYgTokenAssetsOf: (state) => state.ygTokenAssetsOf,
  getApprovedBalance: (state) => state.approvedBalance,
  getDeposit: (state) => state.depositWithPermit,
  getLockPeriod: (state) => state.lockPeriod,
  getLockedTimestamp: (state) => state.lockedTimestamp,
  getYgTokenContract: (state) => state.ygTokenContract,
};

const actions = {
  async fetchTokenContract({ commit, rootState }) {
    const web3 = rootState.accounts.web3;
    const address = rootState.deposit_contracts.tokenAddress;
    console.log('tokenERC20 address:',address);
    const contract = new web3.eth.Contract(rootState.tokenERC20Abi, address);
    commit("setTokenContract", contract);
    const name = await contract.methods.name().call();
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    commit("setTokenInfos",{"type":"ERC20","address":address,"name":name,"symbol":symbol,"decimals":decimals})
  },
  async fetchYgTokenContract({ commit, rootState }) {
    const web3 = rootState.accounts.web3;
    const address = rootState.deposit_contracts.ygTokenAddress
    const contract = new web3.eth.Contract(YgToken.abi, address);
    commit("setYgTokenContract", contract);
    const name = await contract.methods.name().call();
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    const total_assets = await contract.methods.totalAssets().call();
    commit("setYgTokenInfos",{"type":"ERC20","address":address,"name":name,"symbol":symbol,"decimals":decimals,"total_assets":total_assets})
  },
  async fetchYgTokenApy({ commit, rootState }) {
    await rootState.deposit_contracts.ygTokenContract.methods.apy().call().then((res) => {
      commit("setYgTokenApy", res)
    })
  },
  async fetchYgTokenBalance({ commit, rootState }) {
    await rootState.deposit_contracts.ygTokenContract.methods.balanceOf(rootState.accounts.activeAccount).call().then((num) => {
      commit("setYgTokenBalance", num)
    })
  },
  async fetchYgTokenAssetsOf({ commit, rootState }) {
    await rootState.deposit_contracts.ygTokenContract.methods.assetsOf(rootState.accounts.activeAccount).call().then((num) => {
      commit("setYgTokenAssetsOf", num)
    })
  },
  async fetchBalance({ commit, rootState }) {
    await rootState.deposit_contracts.tokenContract.methods.balanceOf(rootState.accounts.activeAccount).call().then((num) => {
      commit("setNum", num)
    });
  },
  async fetchDeposited({ commit, rootState }) {
    await rootState.deposit_contracts.ygTokenContract.methods.rbalanceOf(rootState.accounts.activeAccount).call().then((num) => {
      console.log('fetchDeposited:', num);
      commit("setDeposited", num)
    });
  },
  async fetchLockPeriod({ commit, rootState }) {
    await rootState.deposit_contracts.ygTokenContract.methods.lockPeriod().call().then((num) => {
      console.log('fetchLockPeriod:', num);
      commit("setLockPeriod", num)
    });
  },
  async fetchLockedTimestamp({ commit, rootState }) {
    await rootState.deposit_contracts.ygTokenContract.methods.lockedTimestamp(rootState.accounts.activeAccount).call().then((num) => {
      commit("setLockedTimestamp", num)
    });
  },
  storeTokenAddress({ commit },_address) {
    commit("setTokenAddress", _address);
  },
  storeYgTokenAddress({ commit },_address) {
    commit("setYgTokenAddress", _address);
  },
  async approve({ rootState }, depositAmount) {
    if (rootState.deposit_contracts && rootState.accounts.activeAccount) {
      const tokenContract = rootState.deposit_contracts.tokenContract;
      try {
        await tokenContract.methods.approve(rootState.deposit_contracts.ygTokenAddress, depositAmount).send({from: rootState.accounts.activeAccount});
        return true;
      }
      catch (err) {
        console.log("signature error:",err);
        return false;
      }
    }
  },
  async getApprovedBalanceForUser({rootState, commit}) {
    if (rootState.deposit_contracts.tokenContract && rootState.accounts.activeAccount) {
      await rootState.deposit_contracts.tokenContract.methods.allowance(rootState.accounts.activeAccount, rootState.deposit_contracts.ygTokenAddress).call().then(num => {
        commit('setApprovedBalance', num);
      });
    }
  },
  async getDeposit({rootState}, depositAmount) {
    if (rootState.deposit_contracts.ygTokenContract && rootState.accounts.activeAccount) {
      const ygTokenContract = rootState.deposit_contracts.ygTokenContract;
      try {
        await ygTokenContract.methods.deposit(depositAmount, rootState.accounts.activeAccount).send({from: rootState.accounts.activeAccount});
        return true;
      }
      catch (err) {
        console.log('deposit:',err)
        return false;
      }
    }
  },
  async redeem({rootState}, redeemAmount) {
    if (rootState.deposit_contracts.ygTokenContract && rootState.accounts.activeAccount) {
      const ygTokenContract = rootState.deposit_contracts.ygTokenContract;
      try {
        await ygTokenContract.methods.redeem(redeemAmount, rootState.accounts.activeAccount, rootState.accounts.activeAccount).send({from: rootState.accounts.activeAccount});
        return true;
      }
      catch (err) {
        console.log('redeem:',err)
        return false;
      }
    }
  },
};

const mutations = {
  setNum(state, _num) {
    state.num = _num;
  },
  setDeposited(state, _deposited) {
    state.deposited = _deposited;
  },
  setTokenAddress(state, _address) {
    state.tokenAddress = _address;
  },
  setTokenContract(state, _contract) {
    state.tokenContract = _contract;
  },
  setTokenInfos(state, _infos) {
    state.tokenInfos = _infos;
  },
  setYgTokenAddress(state, _address) {
    state.ygTokenAddress = _address;
  },
  setYgTokenContract(state, _contract) {
    state.ygTokenContract = _contract;
  },
  setYgTokenApy(state, _apy) {
    state.ygTokenApy = _apy;
  },
  setYgTokenBalance(state, _balance) {
    state.ygTokenBalance = _balance;
  },
  setYgTokenAssetsOf(state, _assetsOf) {
    state.ygTokenAssetsOf = _assetsOf;
  },
  setYgTokenInfos(state, _infos) {
    state.ygTokenInfos = _infos;
  },
  setApprovedBalance(state, _approvedBalance) {
    state.approvedBalance = _approvedBalance;
  },
  setLockPeriod(state, _lockPeriod) {
    state.lockPeriod = _lockPeriod;
  },
  setLockedTimestamp(state, _lockedTimestamp) {
    state.lockedTimestamp = _lockedTimestamp;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};