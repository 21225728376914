<template>
  <div :class="wClassName">
    <img ref="img" :class="className" :src="src" :alt="alt">
  </div>
</template>
<script>
const SVGInjector = require('svg-injector')
// if(process.browser)
//     SVGInjector = require('svg-injector')
export default {
    name: 'SvgInjector',
    props: {
        className: {
            type: String,
            default: ''
        },
        src: {
            type: String,
            default: ''
        },
        callback: {
            type: Function,
            default: () => true
        },
        alt: {
            type: String,
            default: ''
        },
        options: {
            type: Object,
            default: undefined
        },
        wClassName: {
            type: String,
            default: ''
        },
    },
    mounted() {
        if(typeof SVGInjector !== undefined) {
            SVGInjector(this.$refs.img, this.options, this.callback);
        }
    }
}
</script>