<template>
    <div :class="'enreach-row '+(token.vetoken?'':'esfrcs')" @click="token.vetoken?this.$router.push({ name: 'single', params: { single: token.id } }):false">
        <div>
            <div :class="'rowcolorcode rcc'+((index%4)+1)"></div>
            <div class="rsolo bl-enr mh15"><TokenIcon :token="token.names[0]" /></div>
            <div class="rowtext">{{token.names[0]}}</div>
        </div>
        <div>{{token.names[1]}}</div>
        <div><div class="rsolo bl-enr"><TokenIcon :token="token.names[0]" /></div></div>
    </div>
</template>
<script>
import TokenIcon from "@/components/TokenIcon";
export default {
  props: {
    token: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: false
    },
  },
  components: {
    TokenIcon,
  },
    
}
</script>