<template>
    <div class="stakecontainer">
        <div class="stakehead">
            <div class="stakeico"><svg-injector :class-name="'js-svg-injector'" src="/img/wallet.svg"></svg-injector></div>
            <div>Buy {{getTokenInfos?.symbol}} Token</div>
        </div>
        <div class="enreach-panel stakepanel">
            <div class="stakelabel">
                <div class="slico"><svg-injector :class-name="'js-svg-injector'" src="/img/enrinsig.svg"></svg-injector></div>
                <div>{{getTokenInfos?.symbol}}</div>
            </div>
            <div class="lplabel">Token Purchasing</div>
            <div class="stakegrid" v-if="isUserConnected">
                <div>BNB:</div>
                <div class="sgv">{{ this.$filters.formatTokenBalance(getActiveBalanceWei, "BNB", 18)}}</div>
                <div>{{getTokenInfos?.symbol}}:</div>
                <div class="sgv">{{ getTokenInfos?.decimals?this.$filters.formatTokenBalance(getNum, getTokenInfos?.symbol, getTokenInfos?.decimals):'' }}</div>
                <div>Bought:<div v-tippy="{ content: 'Tokens that you have already purchased from this interface.', theme: 'yaggr', arrow: false, placement: 'bottom-end' }"><svg-injector :w-class-name="'infoIcon'" :class-name="'js-svg-injector align-super'" src="/img/info.svg"></svg-injector></div></div>
                <div class="sgv">
                    {{ this.$filters.formatTokenBalance(getBought, getTokenInfos?.symbol, getTokenInfos?.decimals) }}
                    <small class="priceFiat" v-if="getBuyTokenInfos?.maxAmount > 0">limit: {{ this.$filters.formatTokenBalance(getBuyTokenInfos.maxAmount, getTokenInfos?.symbol, getTokenInfos?.decimals) }}</small>
                </div>
            </div>
            <ul class="nav nav-tabs justify-content-end mt-4">
                <li class="nav-item"><span class="nav-link simul-link" @click="tabs=1" v-bind:class="{ active: tabs==1 }">Buy</span></li>
                <li class="nav-item"><span class="nav-link simul-link" @click="tabs=false" v-bind:class="{ active: !tabs }">Tools</span></li>
            </ul>
            <div class="fade show pt-4" v-if="tabs==1">
                <div class="form_bg p-4 mx-auto">
                    <div class="sbuts mt-0">
                        <input v-model.number="amountToMigrate" type="number" :autofocus="true" :disabled="approving || depositing" @input="onDepositInput" placeholder="Amount" expanded class="sapprove" />
                        <button :disabled="approving || depositing" class="sapprove" @click="setMax()">
                            Max
                        </button>
                    </div>
                    <div class="sbuts mt-2">
                        <button class="sapprove" :disabled="(!isAmountToDepositValid())" @click="buy">
                            <div>Buy</div>
                        </button>
                        <svg-injector v-if="estimateTokens" :w-class-name="'colArrow'" :class-name="'js-svg-injector align-middle'" src="/img/arrow-right.svg"></svg-injector>
                        <div class="sestimate sgv" v-if="estimateTokens">
                            Received: {{ this.$filters.formatTokenBalance(estimateTokens, getTokenInfos?.symbol, getTokenInfos?.decimals) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="fade show row p-4" v-if="!tabs">
                <div class="col addtometa">
                    <p>
                        Add tokens to your metamask:<br />
                        <a href="javascript:false;" @click="this.$filters.addToMetaMask(getTokenInfos)"> Add {{getTokenInfos?.symbol}} Token </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { utils } from "web3";
// import { utils as utils_eth } from "ethers";
import { mapGetters } from "vuex";

export default {
  name: 'SwapContainer',
  props:{
    single: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getAddresses']),
    ...mapGetters("accounts", [ "getActiveAccount", "isUserConnected", "getChainId", "getWeb3","getActiveBalanceWei","getGazPrice"]),
    ...mapGetters("buy_contracts", ["getTokenInfos", "getBuyTokenInfos", "getNum","getBought","getEstimateGas"]),
    newValue(){
        return this.$filters.getNewValue(this.getBought, this.amountToDeposit)
    },
    estimateTokens(){
        return (this.getBuyTokenInfos?.ratioExchangeNum)?parseInt(this.amountToDeposit * this.getBuyTokenInfos.ratioExchangeNum/this.getBuyTokenInfos.ratioExchangeDen):'';
    },
    activeBalanceAvailable(){
        console.log('getActiveBalanceWei:',typeof this.getActiveBalanceWei, this.getActiveBalanceWei); 
        console.log('getEstimateGas:',typeof this.getEstimateGas, this.getEstimateGas);
        return String(Number(this.getActiveBalanceWei) - (this.getEstimateGas*this.getGazPrice));
    }
  },
  mounted() {
    this.$store.commit('activeLoading',true)
    // this.$store.dispatch("buy_contracts/fetchNum");
    // if web3 provider has not been yet loaded, redirect to root 
    if (!this.getWeb3) {
        document.location.href="/";
    } else {
        this.initContracts();
    }
  },
  beforeUnmount() {
    clearInterval(this.balanceTimeout);
    this.depositValue=false;
    this.redeeming=false;
    this.amountToMigrate=0;
    this.amountToDeposit=0;
    this.amountToRemove=0;
    this.approving=null;
    this.depositing=false;
    this.tabs=0;
  },
  data() {
    return {
        balanceTimeout: null,
        depositValue: null,
        redeeming: false,
        amountToMigrate: 0,
        amountToDeposit: 0,
        amountToRemove: 0,
        amountToRedeem: 0,
        contract: null,
        approving: false,
        depositing: false,
        tabs: 1
    }
  },
  methods: {
    async initContracts() {
        if(this.getChainId){

            let tknAddress = this.getAddresses[this.single]? this.getAddresses[this.single][this.getChainId] : null;
            let swapAddress = this.getAddresses['ENREACHSWAP']? this.getAddresses['ENREACHSWAP'][this.getChainId] : null;
            this.$store.commit("buy_contracts/setTokenAddress",tknAddress);
            this.$store.commit("buy_contracts/setBuyTokenAddress",swapAddress);
            this.$store.dispatch('buy_contracts/fetchTokenContract');
            this.$store.dispatch('buy_contracts/fetchBuyTokenContract');
            this.$store.dispatch("buy_contracts/fetchEstimateGas");

            await this.initBalances().then(()=> {
               this.setMax();
               this.$store.commit('activeLoading',false)
            });
            // return this.getChainId;

            this.balanceTimeout = setInterval(async () => {
                await this.initBalances();
            }, 5000);
        }else{
            // TODO: FIX this loop
            setTimeout(() => {
                this.initContracts();
            }, 500);
        }
    },
    async initBalances(){
        await this.$store.dispatch("accounts/fetchGazPrice");
        await this.$store.dispatch("accounts/fetchActiveBalance");
        await this.$store.dispatch("buy_contracts/fetchBalance");
        await this.$store.dispatch("buy_contracts/fetchBought");
    },
    calculteMax(value){
        let maxAmount = this.getBuyTokenInfos?.maxAmount > 0 ? this.getBuyTokenInfos.maxAmount:0;
        if(maxAmount > 0){
            let maxValue = (maxAmount - this.getBought) * this.getBuyTokenInfos.ratioExchangeDen/this.getBuyTokenInfos.ratioExchangeNum;
            return maxValue > value? value:maxValue;
        }
        return value;
    },
    setMax() {
        // ignore if max is pressed twice
        let maxValue = this.calculteMax(this.activeBalanceAvailable);
        if (utils.isBigNumber(this.amountToDeposit) && maxValue.eq(this.amountToDeposit)) {
            return;
        }
        this.amountToDeposit = String(maxValue);
        this.amountToMigrate = this.$filters.format(this.amountToDeposit, 18, null);
        this.onDepositInput()
    },
    inputToBigNumber(value) {
        try { 
            var validInput = utils.toWei(String(value || 0), 'ether')    
            return utils.toBN(validInput) ;
        }
        catch (err) {
            console.log(err); 
            return null;
        }
    },
    onDepositInput() {
        this.amountToDeposit = this.inputToBigNumber(this.amountToMigrate);
        if(this.activeBalanceAvailable > 0 &&  this.amountToDeposit.gt(utils.toBN(this.activeBalanceAvailable)) ){
            this.setMax();
            return false;
        }
        if(this.amountToDeposit<0){
            this.amountToDeposit = 0;
            this.amountToMigrate = 0;
        }
    },
    isAmountToDepositValid() {
        try {
            let maxValue = this.calculteMax(this.activeBalanceAvailable);
            return this.amountToDeposit && this.amountToDeposit > 0 && this.amountToDeposit.lte(utils.toBN(maxValue));
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },
    async buy() {
        this.depositing = true;
        this.$store.commit('activeLoading',true)
        let depositResult;
        try {
            depositResult = await this.$store.dispatch('buy_contracts/getBuy', this.amountToDeposit);
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("deposit error:",err);
            depositResult = false;
        }
        if (depositResult) {
            this.$toast.success(`Successful depositing`);
            this.amountToDeposit = this.amountToMigrate = 0;
            this.onDepositInput();
            await this.initBalances();
        }
        this.depositing = false;
        this.$store.commit('activeLoading',false)
    },
  },
}
</script>