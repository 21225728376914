<template>
  <div class="container">
    <div class="spinner is-flex is-touch">
      <div class="bar-1 bar"></div>
      <div class="bar-2 bar"></div>
      <div class="bar-3 bar"></div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'spinner',
    };
</script>

<style scoped>
    .is-flex{
        display: flex!important;
    }
    .container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        margin: 0 auto;
        position: relative;
        width: auto;
    }

    .spinner {
      width: 90px;
      height: 150px;
    }

    .spinner > .bar {
        width: 17px;
        height: 60px;
        margin: auto auto;
        border-radius: 4px;
      margin-left: 5px;
    }

    .bar-1 {
        animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s infinite;
    }

    .bar-2 {
        animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms infinite;
    }

    .bar-3 {
        animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms infinite;
    }

    @keyframes square-anim {
        0% {
            height: 60px;
            background-color: #37ecba;
        }
        20% {
            height: 60px;
        }
        40% {
            height: 150px;
            background-color: #FFFFFF;
        }
        80% {
            height: 60px;
        }
        100% {
            height: 60px;
            background-color: #37ecba;
        }
    }
</style>
