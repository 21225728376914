<template>
    <div class="enreach-cardhead">
        <div class="icon"><svg-injector :class-name="'js-svg-injector'" src="/img/brackets.svg"></svg-injector></div>
        <div class="text">Pairs</div>
        <div class="enreach-panel">
            <div class="enreach-table ecosystemfarmstable" v-if="pools.length > 0">
                <div class="thead">
                    <div class="enreach-row">
                        <div>PAIR</div>
                        <!-- <div>DEPOSIT</div> -->
                        <div>APY</div>
                        <div>Boost APY</div>
                    </div>
                </div>
                <div class="tbody">
                    <FarmItem :v-if="loading==false" v-for="(pool, index) in pools" :index="index" :key="pool.id" :master_page="master_page" :pool="pool" />
                </div>
            </div>
            <div class="text" v-else-if="loading==false">Coming Soon</div>
            <div class="text" v-else>Loading</div>
        </div>
    </div>
</template>
<script>
import FarmItem from "@/components/FarmItem.vue";
import { mapGetters } from "vuex";
export default {
  name: 'FarmsPage',
  props:{
    master_page: {
      type: String,
      required: true,
    //   default: 'farms',
    }
  },
  data(){
      return {
        pools: [],
        loading: true
      }
  },
  computed: {
    ...mapGetters(['getAddresses','getMasterInfos']),
  },
  watch: {
    master_page (val) {
      const _pools = this.getMasterInfos[val]
      this.cleanLoop(_pools)
    }
  },
  beforeUnmount() {
    this.pools = [];

  },
  created() {
    const _pools = this.getMasterInfos[this.master_page] || []
    this.cleanLoop(_pools);
  },
  methods: {
    async cleanLoop(_pools){
        let result = [];
        for(let id in _pools){
          let pool = _pools[id];
          const contract = pool?.contract;
          const pool_key = pool?.pool;
          const chain_id = pool?.activeChain;
          if(contract in this.getAddresses && pool_key in this.getAddresses){
            pool.pool_address = this.getAddresses[pool_key][chain_id]['address'];
          }
          pool.id = id;
          result.push(pool);
        }
        this.loading=false;
        this.pools=result;
    },
  },
  components: {
    FarmItem,
  },
}
</script>