// import Vue from 'vue'
import Web3Modal from "web3modal";
import Web3 from "web3";
import Chains from "@/utils/chains.json";

const state = {
  activeAccount: null,
  activeBalance: 0,
  gazPrice: 0,
  chainId: null,
  chainName: null,
  activeChain: 0,
  activeChainName: null,
  web3: null,
  isConnected: false,
  providerW3m: null, // this is "provider" from Web3Modal
  web3Modal: null,
  chains: Chains,
};

const getters = {
  getActiveAccount(state) {
    if (!state.activeAccount) {
      return window.ethereum.selectedAddress;
    }

    return state.activeAccount;
  },
  getActiveBalanceWei(state) {
    return state.activeBalance;
  },
  getActiveBalanceEth(state) {
    return state.activeBalance>0?state.web3.utils.fromWei(state.activeBalance, "ether"):0;
  },
  getGazPrice(state) {
    return state.gazPrice;
  },
  getChainId(state) {
    return state.chainId;
  },
  getChainName(state) {
    return state.chainName;
  },
  getWeb3(state) {
    if (state.web3) {
      return state.web3;
    } else {
      return new Web3(Web3.givenProvider);
    }
  },
  getWeb3Modal(state) {
    return state.web3Modal;
  },
  isUserConnected(state) {
    return state.isConnected;
  },
  getChains(state) {
    return state.chains
  }
};

const actions = {

  async initWeb3Modal({ commit }) {
    const providerOptions = {
      // MetaMask is enabled by default
      // Find other providers here: https://github.com/Web3Modal/web3modal/tree/master/docs/providers
    };
    
    const w3mObject = new Web3Modal({
      cacheProvider: false, // optional
      providerOptions // required
    });

    // This will get deprecated soon. Setting it to false removes a warning from the console.
    window.ethereum.autoRefreshOnNetworkChange = false;

    // if the user is flagged as already connected, automatically connect back to Web3Modal
    if (localStorage.getItem('isConnected') === "true") {
      let providerW3m = await w3mObject.connect();
      commit("setIsConnected", true);

      commit("setActiveAccount", window.ethereum.selectedAddress);
      commit("setChainData", window.ethereum.chainId);
      commit("setWeb3Provider", providerW3m);
      actions.fetchActiveBalance({ commit });
    }

    commit("setWeb3ModalInstance", w3mObject);
  },
  async connectWeb3Modal({ commit }) {
    let providerW3m = await state.web3Modal.connect();
    let chainId = window.ethereum.chainId;
    commit("setIsConnected", true);

    commit("setActiveAccount", window.ethereum.selectedAddress);
    commit("setChainData", chainId);
    commit("setWeb3Provider", providerW3m);
    actions.fetchActiveBalance({ commit });
    if(chainId != state.activeChain){
      commit("setNetwork");
    }
  },

  async disconnectWeb3Modal({ commit }) {
    commit("disconnectWallet");
    commit("setIsConnected", false);
  },
  async switchNetwork({ commit }){
    await commit("setNetwork");
  },
  async setNetwork(){
    let chainId = state.activeChain;
    try {
      return await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainId }],
      });
    } catch (switchError) {
      let chain = Chains[chainId];
      console.log(switchError);
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          return await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [{ 
              chainId: chainId,
              chainName: chain?.name, 
              nativeCurrency: chain?.nativeCurrency,
              rpcUrls: chain?.rpc
            }],
          });
        } catch (addError) {
          console.log(addError);
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  },
  async ethereumListener({ commit }) {

    window.ethereum.on('accountsChanged', (accounts) => {
      if (state.isConnected) {
        commit("setActiveAccount", accounts[0]);
        commit("setWeb3Provider", state.providerW3m);
        actions.fetchActiveBalance({ commit });
        document.location.reload();
      }
    });

    window.ethereum.on('chainChanged', (chainId) => {
      commit("setChainData", chainId);
      commit("setWeb3Provider", state.providerW3m);
      actions.fetchActiveBalance({ commit });
      document.location.reload();
    });

  },

  async fetchActiveBalance({ commit }) {
    let balance = await state.web3.eth.getBalance(state.activeAccount);
    commit("setActiveBalance", balance);
  },
  async fetchGazPrice({commit}){
    await state.web3.eth.getGasPrice().then((result) => {
        commit("setGazPrice", result*1.1);
    })

  }
  
};

const mutations = {

  async disconnectWallet(state) {
    state.activeAccount = null;
    state.activeBalance = 0;
    state.web3 = null;
    if (state.providerW3m.close && state.providerW3m !== null) {
      await state.providerW3m.close();
    }
    state.providerW3m = null;
    await state.web3Modal.clearCachedProvider();

    window.location.href = '/'; // redirect to the Main page
  },
  setActiveAccount(state, selectedAddress) {
    // let devAddress = "0x943c1294312b09fcc3a2c1f78a92e6c7f01692aa" // ygNRCH holders
    const devAddress = "0xA28802d09805993AcBb547FC57Dac634f9328975" // ygNRCH holders 5k
    // const devAddress = "0x4df1affd4e01ebf6f4af06bfbd4f86b5df923684" // ygNRCH holders 131 days ago
    // const devAddress = "0xca7480a1da518a1bb75dddbcaf351c82ddbe68cf" // NRCH holders
    // devAddress = selectedAddress;
    state.activeAccount = process.env.NODE_ENV === "production" ? selectedAddress : devAddress;
  },

  setActiveBalance(state, balance) {
    state.activeBalance = balance;
  },
  setGazPrice(state, price){
    state.gazPrice = price;
  },
  setActiveChain(state, _chainId) {
    state.activeChain = _chainId;
    state.activeChainName = Chains[_chainId]?.name;
  },

  setChainData(state, _chainId) {
    state.chainId = _chainId;
    state.chainName = Chains[_chainId]?.name || "Localhost";
  },

  async setWeb3Provider(state, providerW3m) {
    state.providerW3m = providerW3m;
    state.web3 = new Web3(providerW3m);
  },

  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
    // Vue.toasted.global.defaultSuccess({msg: `isConnected: ${isConnected}`})
    // add to persistent storage so that the user can be logged back in when revisiting website
    localStorage.setItem('isConnected', isConnected);
  },

  setWeb3ModalInstance(state, w3mObject) {
    state.web3Modal = w3mObject;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};