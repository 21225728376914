<template>
    <div :class="(pool.pool?'':'esfrcs')">
        <div class="rowcoincombo">
            <div class="rcombo1" v-if="!pool?.oneIcon"><TokenIcon :token="pool.names[0]" /></div>
            <div class="rcombo2"><TokenIcon :token="pool.names[1]" /></div>
        </div>
        <div class="rowtext">{{pool.names[0]}}</div>
        <div class="rowarrows"><svg-injector :class-name="'js-svg-injector'" src="/img/rowarrows.svg"></svg-injector></div>
        <div class="rowtext">{{pool.names[1]}}</div>
    </div>
    <div class="strategydetails">
        <div class="tabs">
            <div><span @click="tabs=0" :class="'tab '+((tabs==0)?'active':'')">Overview</span></div>
            <div><span @click="tabs=1" :class="'tab '+((tabs==1)?'active':'')">Pool Size</span></div>
            <div><span @click="tabs=2" :class="'tab '+((tabs==2)?'active':'')">Pool Info</span></div>
        </div>
    </div>
    <div v-if="tabs==0">
         <p>Overview content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum eum aperiam dolorum repellendus, aut eos. Libero dicta porro sit iste, sint voluptas pariatur ex repellat hic delectus. Hic, quae fugit.</p>
    </div>
    <ul v-if="tabs==1">
        <li>APY: {{displayPoolAPY}}</li>
        <li>Boost APY: {{displayPoolBoostAPY}}</li>
        <li>Unstaked: ...</li>
    </ul>
    <p v-if="tabs==1"> Pool Size content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum eum aperiam dolorum repellendus, aut eos. Libero dicta porro sit iste, sint voluptas pariatur ex repellat hic delectus. Hic, quae fugit.</p>
    <p v-if="tabs==2"> Pool Info content: Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatum eum aperiam dolorum repellendus, aut eos. Libero dicta porro sit iste, sint voluptas pariatur ex repellat hic delectus. Hic, quae fugit.</p>
    <div :class="'sbuts mt-0 '">
        <button class="sapprove" :disabled="(!pool.pool)" @click="pool.pool?this.$router.push({ name: 'pair', params: { master_page: master_page, pair: pool.id } }):false">funds</button>
    </div>
</template>
<script>
import TokenIcon from "@/components/TokenIcon";
import { mapGetters } from "vuex";
export default {
  props: {
    pool: {
      type: Object,
      required: true,
    },
    master_page: {
      type: String,
      required: true,
    },
  },
  components: {
    TokenIcon,
  },
  beforeUnmount() {
  },
  data() {
      return {
        tabs: 0,
        poolFee:'--',
        poolTotal:0
      }
  },
  methods: {
    poolInfos(){
      let nrchAPI = this.getNrchAPI;
      let pool = this.pool;
      let contract = pool?.contract;
      let pool_address = pool?.pool_address;
      let chain_id = pool?.activeChain;
      if(nrchAPI && contract in nrchAPI && chain_id in nrchAPI[contract] && pool_address in nrchAPI[contract][chain_id]){
        console.log(nrchAPI[contract][chain_id][pool_address]);
        return nrchAPI[contract][chain_id][pool_address];
      }
      return {};
    }
  },
  computed:{
    ...mapGetters(["getNrchAPI"]),
    displayPoolAPY(){
      const infos = this.poolInfos();
      console.log('infos',infos);
      if(infos){
        let apy = infos?.APY?Number(infos.APY).toFixed(2):0;
        // pool.BOOSTAPY = infos?.BOOSTAPY?Number(infos.BOOSTAPY).toFixed(2):0;
        return apy+'%';
      }
      return '--';
    },
    displayPoolBoostAPY(){
      const infos = this.poolInfos();
      if(infos){
        let apy = infos?.BOOSTAPY?Number(infos.BOOSTAPY).toFixed(2):0;
        return apy+'%';
      }
      return '--';
    },
  }    
}
</script>