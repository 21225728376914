import Vuex from 'vuex'
import accounts from "./modules/accounts"
import farms from "./modules/farmsV2"
import buy_contracts from "./modules/buyContracts"
import deposit_contracts from "./modules/depositContracts"
import Addresses from "@/contracts/addresses.json";
import MasterInfos from "@/utils/master.js";
import TokenERC20 from "@/contracts/TERC.json";


export default new Vuex.Store({
    state: {
        activePage:0,
        addresses: Addresses,
        chain: 'bsc',
        checkNetwork: false,
        loading:false,
        master_infos: MasterInfos,
        nrchAPI: {},
        stakePage:false,
        stakeToken:'BNB',
        tokenERC20Abi: TokenERC20.abi,
        ygTknBalance: null,
        ygTknContract: null,
        ygTknInfos: {},
        ygTknMin: 0,
        ygTknNeed: null,
        nrchPrice: null,
    },
    mutations: {
        setChain(state,chain){
            state.chain = chain;
        },
        setCheckNetwork(state, _checkNetwork){
            state.checkNetwork = _checkNetwork;
        },
        navigation(state, i){
            state.activePage=i;
            state.mobMenu=false
        },
        stakeGo(state,s){
            state.stakePage=s;
        },
        activeLoading(state,s){
            state.loading=s;
        },
        setNrchAPI(state,_nrchAPI){
            state.nrchAPI = _nrchAPI;
        },
        setYgTknMin(state,_ygTknMin){
            state.ygTknMin = _ygTknMin;
        },
        setYgTknNeed(state, _ygToken){
            state.ygTknNeed = _ygToken;
        },
        setYgTknContract(state, _contract){
            state.ygTknContract = _contract;
        },
        setYgTknInfos(state, _infos){
            state.ygTknInfos = _infos;
        },
        setYgTknBalance(state, _infos){
            state.ygTknBalance = _infos;
        },
        setNrchPrice(state, _price){
            state.nrchPrice = _price;
        }
    },
    actions: {
        async fetchYgTknContract({ commit, rootState }) {
            const web3 = rootState.accounts.web3;
            const address = (Addresses[rootState.ygTknNeed] && Addresses[rootState.ygTknNeed][rootState.accounts.chainId])? Addresses[rootState.ygTknNeed][rootState.accounts.chainId] : null;
            if(address){
                const contract = new web3.eth.Contract(rootState.tokenERC20Abi, address);
                commit("setYgTknContract", contract);
                const name = await contract.methods.name().call();
                const symbol = await contract.methods.symbol().call();
                const decimals = await contract.methods.decimals().call();
                commit("setYgTknInfos",{"type":"ERC20","address":address,"name":name,"symbol":symbol,"decimals":decimals})
            }else{
                commit("setYgTknContract", null);
                commit("setYgTknInfos", {})
            }
        },
        async fetchYgTknBalance({ commit, rootState }) {
            if(rootState.ygTknContract?.methods){
                await rootState.ygTknContract.methods.balanceOf(rootState.accounts.activeAccount).call().then((num) => {
                    commit("setYgTknBalance", num)
                });
            }else{commit("setYgTknBalance", 0)}
        },
    },
    getters: {
        getNrchAPI(state){
            return state.nrchAPI;
        },
        getAddresses(state){
            return state.addresses;
        },
        getMasterInfos(state){
            return state.master_infos;
        },
        getYgTknBalance(state){
            return state.ygTknBalance;
        },
        getYgTknContract(state){
            return state.ygTknContract;
        },
        getYgTknInfos(state){
            return state.ygTknInfos;
        },
        getYgTknMin(state){
            return state.ygTknMin;
        },
        getYgTknNeed(state){
            return state.ygTknNeed;
        },
        getNrchPrice(state){
            return state.nrchPrice;
        }
    },
    modules: {
        accounts,
        farms,
        buy_contracts,
        deposit_contracts
    }
})