import { createRouter, createWebHistory } from 'vue-router'
import StrategiesPage from './views/StrategiesPage.vue'
// import OptionPage from './views/OptionPage.vue'
import TradesPage from './views/TradesPage.vue'
import FarmsPage from './views/FarmsPage.vue'
import SingleSidedFarms from './views/SingleSidedFarms.vue'
import GovernanceStaking from './views/GovernanceStaking.vue'
import FarmsContainer from './views/FarmsContainer.vue'
import DepositContainer from './views/DepositContainer.vue'
import SwapContainer from './views/SwapContainer.vue'
// import DashboardList from './views/DashboardList.vue'
// import Dashboard from './views/Dashboard.vue'
// import MyToken from './views/MyToken.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: SingleSidedFarms,
      meta:{
        page:0,
        stakePage: false
      }
    },
    {
      path: '/test',
      component: StrategiesPage,
      props: {
        master_page:"ecosystem",
      },
      meta:{
        page:1,
        stakePage: false,
        checkNetwork: false
      }
    },
    {
      path: '/ecosystem',
      component: FarmsPage,
      props: {
        master_page:"ecosystem",
      },
      meta:{
        page:1,
        stakePage: false,
        checkNetwork: false
      }
    },
    {
      path: '/nreserve',
      component: FarmsPage,
      props:{
        master_page:"nreserve",
      },
      meta:{
        page:2,
        stakePage: false
      }
    },
    {
      path: '/governance-staking',
      component: GovernanceStaking,
      meta:{
        page:3,
        stakePage: false
      }
    },
    {
      path: '/:master_page/:pair',
      name: 'pair',
      props: true,
      component: FarmsContainer,
      meta:{
        page:1,
        stakePage: true,
        checkNetwork: true
      }
    },
    {
      path: '/single-token/:single',
      name: 'single',
      props: true,
      component: DepositContainer,
      meta:{
        page:0,
        stakePage: true,
        checkNetwork: true
      }
    },
    {
      path: '/swap-token/:single',
      name: 'swap',
      props: true,
      component: SwapContainer,
      meta:{
        stakePage: true,
        checkNetwork: true
      }
    },
    // {
    //   path: '/dashboard/:token',
    //   name: 'mytoken',
    //   props: true,
    //   component: MyToken,
    //   meta:{
    //     page:10,
    //     stakePage: true,
    //     checkNetwork: false
    //   }
    // },
    {
      path: '/trades',
      name: 'trades',
      props: true,
      component: TradesPage,
      meta:{
        stakePage: true,
        checkNetwork: false
      }
    },
    // {
    //   path: '/dashboard',
    //   name: 'dashboard',
    //   component: DashboardList,
    //   props: true,
    //   meta:{
    //     stakePage: false,
    //     page: 4,
    //     master_page:"dashboard",
    //     checkNetwork: false
    //   }
    // },
    // {
    //   path: '/dashboard/:single',
    //   name: 'mydetails',
    //   component: Dashboard,
    //   props: true,
    //   meta:{
    //     page:4,
    //     stakePage: true,
    //     master_page:"dashboard",
    //     checkNetwork: true
    //   }
    // }
  ],
})

export default router;
