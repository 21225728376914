<template>
    <!--div class="sidemenu" v-bind:class="{ active: mobMenu }" v-if="!stakePage"-->
    <div class="sidemenu"  v-bind:class="{ active: this.$store.state.mobMenu }">
        <div class="toplogo"></div>
        <div class="smclose" @click="this.$store.state.mobMenu=!this.$store.state.mobMenu"><svg-injector :class-name="'js-svg-injector'" src="/img/closemenu.svg"></svg-injector></div>
        <div class="smlist"
            v-for="(item, index) in pages"
            v-bind:key="item.name">
          <ItemMenu :item=item :activePage=activePage :index=index />
        </div>
    </div>
</template>
<script>
import ItemMenu from './ItemMenu.vue'
export default {
  name: 'SideMenu',
  props: ['pages','activePage','stakePage'],
  components: {
    ItemMenu
  },
}
</script>
<style scoped></style>