<template>
    <div v-if="pool?.names" :class="'line '+((pool.idx==index)?'active':'')">
        <div>
            <div class="rowcoincombo">
                <div class="rcombo1" v-if="!pool?.oneIcon"><TokenIcon :token="pool.names[0]" /></div>
                <div class="rcombo2"><TokenIcon :token="pool.names[1]" /></div>
            </div>
            <div class="rowtext">{{pool.names[0]}}</div>
            <div class="rowarrows"><svg-injector :class-name="'js-svg-injector'" src="/img/rowarrows.svg"></svg-injector></div>
            <div class="rowtext">{{pool.names[1]}}</div>
        </div>
    </div>
</template>
<script>
import TokenIcon from "@/components/TokenIcon";
export default {
  props: {
    pool: {
      type: Object,
      required: true,
    },
    master_page: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: false
    },
  },
  components: {
    TokenIcon,
  },
  mounted() {

  },
  beforeUnmount() {
  },
  data() {
      return {
      }
  },
  methods: {
    poolInfos(){
      return {};
    }
  },
  computed:{
  }    
}
</script>