<template>
    <div class="enreach-cardhead">
        <div class="icon"><svg-injector :class-name="'js-svg-injector'" src="/img/enr.svg"></svg-injector></div>
        <div class="text">NRCH Traders Ranking</div>
        <div class="enreach-panel">
          <div class="enreach-table strategiespage" v-if="filteredTrades">
            <div>
                <ul class="nav nav-tabs">
                    <li v-for="(net,index) in networks" v-bind:key="net.key" class="nav-item">
                        <span :class="(index!=0?'ms-4':'')+' nav-link simul-link '+((net.key==network)?'active':'')" @click="selectNetwork(net.key)">{{net.name}}</span>
                    </li>
                </ul>
                <div class="filters mt-4" v-if="getActiveAccount && myPosition">
                    <div class="col form-filters">Your position for <strong>{{getActiveAccount}}</strong> address is {{myPosition[0]}} for {{(myPosition[4]/10**9).toFixed(3)}} points.</div>
                </div>
                <div class="filters justify-content-between mt-3">
                    <input v-model="search" class="col-8 form-filters" aria-label="Filter trades by address" placeholder="Filter trades by address" />
                    <select class="col-2 form-filters" v-model="period" v-if="trades['last_week']">
                        <option class="seloption" disabled value="" >Select a period</option>
                        <option class="seloption" value="this_week">This week</option>
                        <option class="seloption" value="last_week">Last week</option>
                        <option class="seloption" value="this_month">This Month</option>
                        <option class="seloption" value="all">All time</option>
                    </select>
                    <select class="col-1 form-filters" v-model="pageRows">
                        <option class="seloption" disabled value="" >Row count</option>
                        <option class="seloption" value="10">10</option>
                        <option class="seloption" value="20">20</option>
                        <option class="seloption" value="50">50</option>
                        <option class="seloption" value="100">100</option>
                    </select>
                </div>
            </div>
            <div class="tbody mt-2">
                <table class="table table-sm">
                    <thead class="thead">
                        <tr class="enreach-row"><!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                            <th v-for="(column, i) in (['this_week','last_week'].includes(period)?columns.concat('ygNRCH'):columns)" :key="i" >
                                <span :class="'simul-link '+((sortKey == i)?'active':'')" @click="sort(i)" >
                                    {{ column }}
                                </span>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(trade, idx) in sortedTrades" :key="idx">
                            <td v-for="(out, i) in trade" :key="i"><strong v-if="(i==2 || i==3 || i==4 || i==8)">{{(trade[i]/10**9).toFixed(3)}}</strong><span v-else>{{trade[i]}}</span></td>
                        </tr>
                    </tbody>
                </table>
                <nav v-if="Math.round(filteredTrades?.length/pageRows)>1">
                    <ul class="pagination pagination-sm justify-content-end">
                        <li class="page-item">
                            <span class="page-link simul-link" aria-label="Previous" @click="prevPage">
                                <span aria-hidden="true">&laquo;</span>
                            </span>
                        </li>
                        <li v-for="n in paginate" :key="n" :class="'page-item '+((currentPage == n)?'active':'')" :aria-current="(currentPage == n)?'page':''"><span :class="'page-link '+(('...' != n)?'simul-link':'')" @click="goPage(n)">{{n}}</span></li>
                        <li class="page-item">
                            <span class="page-link simul-link" aria-label="Next" @click="nextPage">
                                <span aria-hidden="true">&raquo;</span>
                            </span>
                        </li>
                    </ul>
                </nav>
            </div>
          </div>
          <div class="text" v-else-if="loading==false">Coming Soon</div>
          <div class="text" v-else>Loading</div>
        </div>
    </div>
</template>
<style scoped>

    .enreach-cardhead .icon{
        width: 30px;
        height: 30px;
        display: none;
    }

    .enreach-cardhead .text{
            font-size: 32px;
        font-weight: 600;
        margin-top: 36px;
        margin-bottom: 30px;
        /* float: left; */
    }
    .table {
        --bs-table-bg: transparent;
        --bs-table-accent-bg: transparent;
        --bs-table-striped-color: #37ecba;
        --bs-table-striped-bg: rgba(255, 255, 255, 0.05);
        --bs-table-active-color: #37ecba;
        --bs-table-active-bg: rgba(255, 255, 255, 0.1);
        --bs-table-hover-color: #37ecba;
        --bs-table-hover-bg: rgba(255, 255, 255, 0.075);
        width: 100%;
        margin-bottom: 1rem;
        color: #FFF;
        vertical-align: top;
        border-color: #dee2e6;
    }
    .form-floating > .form-select~label,
    .table th{
        font-size: 13px;
        font-weight: 700;
        opacity: 1;
        text-transform: uppercase;
    }
    .table>tbody>tr:nth-of-type(even) {
        --bs-table-accent-bg: var(--bs-table-striped-bg);
        color: var(--bs-table-striped-color);
    }
    .filters{
        display: flex;
        flex-wrap: wrap;
    }
    .form-filters{
        display: inline-table;
        font-size: 18px;
        font-weight: 500;
        padding: 10px 10px;
        border-radius: 5px;
        font-family: 'Rajdhani', sans-serif;
        background: none;
        -moz-appearance: textfield;
        border: none;
        color: #FFF;
        background: #00006080;
    }
    .form-filters::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff80;
    opacity: 1; /* Firefox */
    }
    .form-filters::-webkit-outer-spin-button,
    .form-filters::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    .form-filters:disabled {
        /* opacity: 0.5; */
        color:rgba(255,255,255,.5);
        border-color: rgba(55,236,186, 0.5);
    }
    .form-filters:disabled .vue-custom-tooltip:after{
        background-color: rgba(255,255,255,.5);
    }

    .form-filters > *{
        display: inline-block;
        vertical-align: middle;
    }
    .page-link{
        font-family: 'Rajdhani', sans-serif;
        border: 1px solid #37ecba;
        background-color: transparent;
        color: #FFF;
    }
    .page-item.active .page-link {
        z-index: 3;
        color: #113898;
        background-color: #37ecba;
        border-color: #37ecba;
    }
    .nav-link.disabled {
    color: #ffffff60;
    pointer-events: none;
}
    .nav-link{
        font-weight: 500;
        padding: 0 0 .75rem 0;
        position: relative;
        border: none;
        color: #ffffffd0;
        font-size: 1.2em;
    }
    .nav-link.active::after{
        width: 100%;
        height: 3px;
        background: #37ecba;
        position: absolute;
        content: "";
        bottom: 0px;
        left: 0;
    }
    .nav-tabs .nav-link:not(.active):hover {
        border: none;
        color: #FFF;
    }
    .nav-tabs .nav-link:not(.active):hover::after {
        width: 100%;
        height: 3px;
        background: #37ecba80;
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
    }
    .nav-tabs .nav-link.active {
        color: #ffffff;
    }
    tbody td {
        padding: 0.5rem 0.25rem;
        border-color: #ffffff00;
    }
    .table>tbody>tr:nth-of-type(2n) {
        --bs-table-accent-bg: #00006060;
        color: #ffffff;
    }
    .page-link[data-v-af5f9768] {
        border: none;
        background-color: #00006080;
    }
</style>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  name: 'TradesPage',
  data(){
      return {
        sortKey: 3, 
        sortDirection: 1,
        pageRows: 10,
        currentPage: 1,
        search: '',
        columns: ['Rank','Account', "Bought", 'Sold', "Points", "Trades", "Sells", "Buys"],
        network: 'both',
        networks: [
            {'name': 'BSC & ETH', 'key':'both'},
            {'name': 'ETH', 'key':'eth'},
            {'name': 'BSC', 'key':'bsc'},
        ],
        period: 'all',
        trades: {},
        loading: true,
        initApiTimeout: null,
      }
  },
  watch: {
    search() {
        // reset to first page when execute search 
        this.currentPage = 1;
    },
    network() {
        // reset to first page when change network 
        this.currentPage = 1;
    },
    pageRows() {
        // reset to first page when change page rows 
        this.currentPage = 1;
    },
    period() {
        // reset to first page when change period 
        this.currentPage = 1;
        
    }
  },
  computed: {
    ...mapGetters("accounts", ["getActiveAccount"]),
    myPosition() {
        const _periodTrades = this.trades[this.period]
        if (!_periodTrades) return [];
        const _trades = _periodTrades[this.network]
        if(this.getActiveAccount == '') return false;
        const result = _trades?.filter(c => {
            return c[1].toLowerCase().indexOf(this.getActiveAccount.toLowerCase()) >= 0; // c[1] is account address
        })
        return result[0] || null;
    },
    filteredTrades() {
        const _periodTrades = this.trades[this.period]
        if (!_periodTrades) return [];
        const _trades = _periodTrades[this.network]
        return _trades?.filter(c => {
            if(this.search == '') return true;
            return c[1].toLowerCase().indexOf(this.search.toLowerCase()) >= 0; // c[1] is account address
        })
    },
    sortedTrades() {
      const _trades = this.filteredTrades
      const type = this.sortBy === 1 ? 'String' : 'Number'
      const direction = this.sortDirection
      const head = this.sortBy
      return _trades?.sort(this.sortMethods(type, head, direction)).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageRows;
        let end = this.currentPage*this.pageRows;
        if(index >= start && index < end) return true;
      })
    },
    paginate(){
        let pages = [];
        const onSides = 2;
        const last_page = Math.ceil(this.filteredTrades.length / this.pageRows);
        for (let i = 1; i <= last_page; i++) {
            let offset = (i == 1 || last_page) ? onSides + 1 : onSides;
            if (i == 1 || (this.currentPage - offset <= i && this.currentPage + offset >= i) ||
                i == this.currentPage || i == last_page) {
                pages.push(i);
            } else if (i == this.currentPage - (offset + 1) || i == this.currentPage + (offset + 1)) {
                pages.push('...');
            }
        }
        return pages;
    }
  },
  async created() {
    await this.initTrades();
    this.loading = false;
    await this.detailsTrades();
  },
  mounted() {
    this.initApiTimeout = setInterval(async () => {
      this.initTrades();
      this.detailsTrades();
    }, 60000);
  },
  beforeUnmount() {
    clearInterval(this.initApiTimeout);
  },
  methods: {
    sort(head) {
        this.sortBy = head
        this.sortDirection *= -1
    },
    sortMethods(type, head, direction) {
       switch (type) {
          case 'String': {
            return direction === 1 ?
              (a, b) => b[head] > a[head] ? -1 : a[head] > b[head] ? 1 : 0 :
              (a, b) => a[head] > b[head] ? -1 : b[head] > a[head] ? 1 : 0 
          }
          case 'Number': {
            return direction === 1 ?
              (a, b) => Number(b[head]) - Number(a[head]) :
              (a, b) => Number(a[head]) - Number(b[head])
          } 
       }
    },
    nextPage() {
      if((this.currentPage*this.pageRows) < this.filteredTrades.length) this.currentPage++;
    },
    prevPage() {
      if(this.currentPage > 1) this.currentPage--;
    },
    goPage(n) {
        console.log(typeof n);
      if(typeof n !== 'number') return false;
      this.currentPage = n
    },
    async initTrades(){
        await axios.get(`/static/tradesAPI.json?t=${Math.round(new Date().getTime()/10**5)}`,
        { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0', } })
        .then((response) => {
            this.trades = {...this.trades, ['all']:response.data}
        })
        .catch(err => {
            console.log(err);
            throw err;
        })
    },
    async detailsTrades(){
        await axios.get(`/static/tradesAPI-details.json?t=${Math.round(new Date().getTime()/10**5)}`,
        { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0', } })
        .then((response) => {
            // "this_week","last_week","this_month"
            this.trades = {...this.trades, ['this_week']:response.data['this_week'], ['last_week']:response.data['last_week'], ['this_month']:response.data['this_month']}
        })
        .catch(err => {
            console.log(err);
            throw err;
        })
    },
    selectNetwork(_network){
        this.network = _network;
    },
    sortBy(sortKey) {
        this.reverse = (this.sortKey == sortKey) ? ! this.reverse : false;
        this.sortKey = sortKey;
    }
  },
}
</script>