<template>
    <svg-injector :class-name="'js-svg-injector '+className" :src="this.$filters.tokenImage(token)"></svg-injector>
</template>
<script>
export default {
    props:{
        token: {
            type: String,
            default: "NRCH",
        },
        className: {
            type: String,
            required: false,
        }
    },
}
</script>