<template>
    <div class="stakecontainer">
        <div class="stakehead">
            <div class="stakeico"><svg-injector :class-name="'js-svg-injector'" src="/img/icostake.svg"></svg-injector></div>
            <div>Deposit {{getTokenInfos?.symbol}} Token</div>
        </div>
        <div class="enreach-panel stakepanel">
            <div class="stakelabel">
                <div class="slico"><svg-injector :class-name="'js-svg-injector'" src="/img/enrinsig.svg"></svg-injector></div>
                <div>{{getTokenInfos?.symbol}}</div>
            </div>
            <div class="lplabel">Token Depositing</div>
            <div class="stakegrid" v-if="isUserConnected">
                <div>BALANCE:</div>
                <div class="sgv">{{ getTokenInfos?.decimals?this.$filters.formatTokenBalance(getNum, getTokenInfos?.symbol, getTokenInfos?.decimals):'' }}</div>
                <div>DEPOSITED:</div>
                <div class="sgv">
                    {{ getTokenInfos?.decimals?this.$filters.formatTokenBalance(newValue, getTokenInfos?.symbol, getTokenInfos?.decimals) : ''}}
                    <small class="priceFiat">{{ getYgTokenInfos?.decimals?this.$filters.formatTokenBalance(getYgTokenBalance, getYgTokenInfos?.symbol, getYgTokenInfos?.decimals) : ''}}</small>
                </div>
            </div>
            <ul class="nav nav-tabs mt-4">
                <li class="nav-item"><span class="nav-link simul-link" @click="tabs=false" v-bind:class="{ active: !tabs }">Tools</span></li>
                <li class="nav-item ms-4"><div v-tippy="{ content: (getNum==0?'Unavailable':'Your '+getTokenInfos?.symbol+' tokens will be locked for '+lockPeriod+'!'), theme: 'yaggr', arrow: false, placement: 'top' }"><span class="nav-link simul-link" @click="tabs=1" v-bind:class="{ active: tabs==1, disabled: getNum==0 }">Deposit</span></div></li>
                <li class="nav-item ms-4"><span class="nav-link simul-link" @click="tabs=2" v-bind:class="{ active: tabs==2, disabled: newValue==0 }">Redeem</span></li>
            </ul>

            <div class="fade show pt-4" v-if="tabs==1">
                <div v-if="getNum > 0" class="form_bg p-4 mx-auto">
                    <div class="sbuts mt-0">
                        <input v-model.number="amountToMigrate" type="number" :autofocus="true" :disabled="approving || depositing" @input="onDepositInput" placeholder="Amount" expanded class="sapprove" />
                        <button :disabled="approving || depositing" class="sapprove" @click="setMax()">
                            Max
                        </button>
                    </div>
                    <div class="sbuts mt-2">
                        <button class="sapprove" :disabled="(approved || !isAmountToDepositValid())" @click="approve">
                            <div class="saicon"><svg-injector :class-name="'js-svg-injector'" src="/img/sapprove.svg"></svg-injector></div>
                            <div>APPROVE CONTRACT</div>
                        </button>
                        <svg-injector :w-class-name="'colArrow'" :class-name="'js-svg-injector align-middle'" src="/img/arrow-right.svg"></svg-injector>
                        <button class="sapprove" :disabled="(!approved || !isAmountToDepositValid())" @click="deposit">
                            <div>DEPOSIT</div>
                        </button>
                    </div>
                </div>
            </div>
            <div class="fade show pt-4" v-if="tabs==2">
                <div v-if="displayTimerLocked" class="text-center">
                    <p>
                        You can't redeem before: {{displayTimerLocked.toLocaleDateString("en-GB")}} at {{displayTimerLocked.toLocaleTimeString("en-GB")}}<br />
                    </p>
                </div>
                <div v-else-if="newValue > 0" class="form_bg p-4 mx-auto">
                    <div class="sbuts mt-0 addSymbol">
                        <input v-model.number="amountToRemove" type="number" :autofocus="true" :disabled="redeeming" @input="onRedeemInput" placeholder="Amount" expanded class="sapprove addSymbol" />
                        <label v-if="getYgTokenInfos?.symbol">{{getYgTokenInfos?.symbol}}</label>
                        <button :disabled="redeeming" class="sapprove" @click="setMax('redeem')">
                            Max
                        </button>
                    </div>
                    <div v-if="estimateValue">You'll receive: {{ estimateValue }}</div>
                    <div class="sbuts mt-2">
                        <button class="sapprove" :disabled="(!redeemed || !isAmountToRedeemValid())" @click="redeem">
                            <div>REDEEM</div> 
                        </button>
                    </div>
                </div>
            </div>
            <div class="fade show row pt-4" v-if="!tabs">
                <!-- <div class="col" v-if="(getNum == 0 && newValue == 0 && buyLink)"> -->
                <div class="col" v-if="buyLink">
                    <p>To acquire the <strong>{{this.getTokenInfos?.symbol}} tokens</strong>,  please click here:</p>
                    <a :href="buyLink" :target="buyLink.match(/\/\//g)?'_blank':'_self'" class="stakebut">
                        Buy here 
                    </a>
                </div>
                <div class="col addtometa">
                    <p>
                        Add tokens to your metamask:<br />
                        <span @click="this.$filters.addToMetaMask(getTokenInfos)" class="simul-link"> Add {{getTokenInfos?.symbol}} Token </span><br />
                        <span @click="this.$filters.addToMetaMask(getYgTokenInfos)" class="simul-link"> Add {{getYgTokenInfos?.symbol}} Token </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .addSymbol {
        position: relative;
    }
    .addSymbol input{
        padding-right: 100px;
    }
    .addSymbol label {
        position: absolute;
        width: 90px;
        text-align: right;
        padding: 21px 0;
        transform: translateX(-105px);
        font-size: 18px;
        font-weight: 600;
        color: #37ecba;
        font-family: 'Rajdhani', sans-serif;
    }
    .nav-link.disabled {
    color: #ffffff60;
    pointer-events: none;
}
    .nav-link{
        font-weight: 500;
        padding: 0 0 .75rem 0;
        position: relative;
        border: none;
        color: #ffffffd0;
        font-size: 1.2em;
    }
    .nav-link.active::after{
        width: 100%;
        height: 3px;
        background: #37ecba;
        position: absolute;
        content: "";
        bottom: 0px;
        left: 0;
    }
    .nav-tabs .nav-link:not(.active):hover {
        border: none;
        color: #FFF;
    }
    .nav-tabs .nav-link:not(.active):hover::after {
        width: 100%;
        height: 3px;
        background: #37ecba80;
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
    }
    .nav-tabs .nav-link.active {
        color: #ffffff;
    }
</style>
<script>
import { utils } from "web3";
// import { utils as utils_eth } from "ethers";
import { mapGetters } from "vuex";

export default {
  name: 'DepositContainer',
  props:{
    single: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getAddresses']),
    ...mapGetters("accounts", ["getActiveAccount", "isUserConnected", "getChainId", "getWeb3"]),
    ...mapGetters("deposit_contracts", ["getTokenInfos","getYgTokenInfos","getYgTokenBalance", "getNum","getDeposited","getApprovedBalance","getLockPeriod","getLockedTimestamp","getYgTokenContract"]),
    decimalsToUnit(){
        return (this.getTokenInfos && this.getTokenInfos.decimals)? this.$filters.decimalToUnit(this.getTokenInfos.decimals) : 'ether';
    },
    newValue(){
        return this.$filters.getNewValue(this.getDeposited, this.amountToDeposit)
    },
    buyLink(){
        return this.getAddresses[this.single]? this.getAddresses[this.single]["url"] : null;
    },
    lockPeriod(){
        const lockPeriod = Number(this.getLockPeriod) || 0;
        if(lockPeriod === 0) return "No lock period";
        const one_minute = 60;
        const one_hour = 60 * one_minute;
        const one_day = 24 * one_hour;

        const daysLocked = (lockPeriod/one_day).toFixed(0);
        const hourLocked = ((lockPeriod/one_hour) % 24).toFixed(0);
        const minuteLocked = ((lockPeriod/one_minute) % one_minute).toFixed(0);
        let str = "";
        str += (daysLocked>1? ` ${daysLocked} days`: daysLocked===1? ` ${daysLocked} day`: "");
        str += (hourLocked>1? ` ${hourLocked} hours`: hourLocked===1? ` ${hourLocked} hour`: "");
        str += (minuteLocked>1? ` ${minuteLocked} minutes`: minuteLocked===1? ` ${minuteLocked} minute`: "");
        return str;
    },
    displayTimerLocked(){
        const lockedTime = Number(this.getLockedTimestamp) || 0;
        const endDateDateTimeObj = new Date(1000*lockedTime)
        if(lockedTime == 0 || Date.now() > endDateDateTimeObj){
            return false;
        }
        return endDateDateTimeObj;
    },
  },
  mounted() {
    // this.$store.dispatch("deposit_contracts/fetchNum");
    // if web3 provider has not been yet loaded, redirect to root 
    if (!this.getWeb3) {
        document.location.href="/";
    } else {
        this.$store.commit('activeLoading',true)
        this.initContracts();
    }
  },
  beforeUnmount() {
    clearInterval(this.balanceTimeout);
    clearTimeout(this.estimateTimer)
    this.depositValue=false;
    this.redeeming=false;
    this.amountToMigrate=0;
    this.amountToDeposit=0;
    this.amountToRemove=0;
    this.amountToRedeem=0;
    this.approving=null;
    this.redeemed=null;
    this.depositing=false;
    this.approved=null;
    this.tabs=0;
  },
  watch: {
    amountToRedeem: function (val) {
        // previewRedeem
        this.estimateValue = 'loading...'
        clearTimeout(this.estimateTimer)
        this.estimateTimer = setTimeout(async() => {
            const value = await this.getYgTokenContract.methods.previewRedeem(val).call()
            this.estimateValue = this.getTokenInfos?.decimals?this.$filters.formatTokenBalance(value, this.getTokenInfos?.symbol, this.getTokenInfos?.decimals) : ''
        }, 1000);
    },
    getChainId: function () {
        this.initContracts();
    },
  },
  data() {
    return {
        balanceTimeout: null,
        estimateTimer: null,
        depositValue: null,
        redeeming: false,
        amountToMigrate: 0,
        amountToDeposit: 0,
        amountToRemove: 0,
        amountToRedeem: 0,
        estimateValue: null,
        contract: null,
        approving: false,
        depositing: false,
        tabs: 0,
        approved: null,
        redeemed: null
    }
  },
  methods: {
    async approve(){
        this.$store.commit('activeLoading',true)
        this.approving = true;
        let approveResult;
        try {
            approveResult = await this.$store.dispatch('deposit_contracts/approve', this.amountToDeposit)
            .then(res => (res)?this.$toast.success(`Successful approving`):'');
        }
        catch(err) {
            this.$toast.error(`approval error`);
            console.log(err);
            approveResult = false;
        }
        this.$store.commit('activeLoading',false)
        this.approving = false;
        this.approved = approveResult;
    },
    async initContracts() {
        if(this.getChainId){

            let ygTkn = `yg${this.single}`;
            let tknAddress = this.getAddresses[this.single]? this.getAddresses[this.single][this.getChainId] : null;
            let ygAddress = this.getAddresses[ygTkn]? this.getAddresses[ygTkn][this.getChainId] : null;

            // this.$store.dispatch("deposit_contracts/storeTokenAddress",tknAddress);
            // this.$store.dispatch("deposit_contracts/storeYgTokenAddress",ygAddress);
            this.$store.commit("deposit_contracts/setTokenAddress",tknAddress);
            this.$store.commit("deposit_contracts/setYgTokenAddress",ygAddress);
            this.$store.dispatch('deposit_contracts/fetchTokenContract');
            this.$store.dispatch("deposit_contracts/fetchYgTokenContract");
            this.$store.dispatch("deposit_contracts/fetchYgTokenBalance");
            await this.$store.dispatch("deposit_contracts/fetchLockPeriod");
            await this.initBalances()//.then(this.$toast.success(`Contracts are initialized`));
            // return this.getChainId;

            this.balanceTimeout = setInterval(async () => {
                await this.initBalances();
            }, 5000);
            this.$store.commit('activeLoading',false)
        }
    },
    async initBalances(){
        await this.$store.dispatch("deposit_contracts/fetchBalance");
        await this.$store.dispatch("deposit_contracts/fetchDeposited");
        await this.$store.dispatch("deposit_contracts/fetchYgTokenBalance");
        await this.$store.dispatch('deposit_contracts/getApprovedBalanceForUser');
        if(this.getDeposited > 0 || this.getLockedTimestamp > 0){
            await this.$store.dispatch("deposit_contracts/fetchLockedTimestamp");
        }
        this.initTabs();
        // this.setMax(); // TODO: Fix to init
    },
    initTabs(){
        if(this.getNum == 0 && this.tabs == 1){
            this.tabs=this.newValue == 0?false:2;
        }else if(this.newValue == 0 && this.tabs == 2){
            this.tabs=this.getNum == 0?false:1;
        }else if(this.tabs === 0){
            this.tabs = this.getNum > 0?1:this.newValue>0?2:false;
        }
    },
    approvedBalance() {
        try {
          return utils.toBN(this.getApprovedBalance);
        }
        catch {
            return utils.toBN("0")
        }
    },
    setMax(redeem=false) {
        // ignore if max is pressed twice
        if(!redeem){
            if (utils.isBigNumber(this.amountToDeposit) && this.getNum.eq(this.amountToDeposit)) {
                return;
            }
            this.amountToDeposit = this.getNum;
            this.amountToMigrate = this.$filters.format(this.amountToDeposit, this.getTokenInfos?.decimals, null);
            this.onDepositInput()
        }else{
            if (utils.isBigNumber(this.amountToRedeem) && this.getYgTokenBalance.eq(this.amountToRedeem)) {
                return;
            }
            this.amountToRedeem = this.getYgTokenBalance;
            this.amountToRemove = this.$filters.format(this.amountToRedeem, this.getYgTokenInfos?.decimals, null);
            this.onRedeemInput()
        }   
    },
    inputToBigNumber(value) {
        try { 
            var validInput = utils.toWei(String(value || 0), this.decimalsToUnit)    
            return utils.toBN(validInput) ;
        }
        catch (err) {
            console.log(err); 
            return null;
        }
    },
    onDepositInput() {
        this.amountToDeposit = this.inputToBigNumber(this.amountToMigrate);
        // this.approved = false;
        if(this.getNum > 0 &&  this.amountToDeposit.gt(utils.toBN(this.getNum)) ){
            this.setMax();
            return false;
        }
        if (
          this.amountToDeposit 
          && this.amountToDeposit > 0
          && this.approvedBalance().gte(this.amountToDeposit)
        ) {
          // Account has on-chain approval
          this.approved = true;
        }
        else {
          // No on-chain approval
          this.approved = false;
        }
    },
    onRedeemInput() {
        this.amountToRedeem = this.inputToBigNumber(this.amountToRemove);
        // this.approved = false;
        let checkValue = utils.toBN(this.newValue)
        if(checkValue > 0 &&  this.amountToRedeem.gt(checkValue) ){
            this.setMax('redeem');
            return false;
        }
        if (
          this.amountToRedeem 
          && this.amountToRedeem > 0
          && checkValue.gte(this.amountToRedeem)
        ) {
          // Account has on-chain approval
          this.redeemed = true;
        }
        else {
          // No on-chain approval
          this.redeemed = false;
        }
    },
    isAmountToDepositValid() {
        try {
            return this.amountToDeposit && this.amountToDeposit > 0 && this.amountToDeposit.lte(utils.toBN(this.getNum));
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },
    isAmountToRedeemValid() {
        try {
            return this.amountToRedeem && this.amountToRedeem > 0 && this.amountToRedeem.lte(utils.toBN(this.newValue));
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },
    async deposit() {
        this.depositing = true;
        this.$store.commit('activeLoading',true)
        let depositResult;
        try {
            depositResult = await this.$store.dispatch('deposit_contracts/getDeposit', this.amountToDeposit);
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("deposit error:",err);
            depositResult = false;
        }
        if (depositResult) {
            this.$toast.success(`Successful depositing`);
            this.amountToDeposit = this.amountToMigrate = 0;
            this.onDepositInput();
            await this.initBalances();
        }
        this.depositing = false;
        this.$store.commit('activeLoading',false)
    },
    async redeem() {
        this.redeeming = true;
        this.$store.commit('activeLoading',true)
        let redeemResult;
        try {
            redeemResult = await this.$store.dispatch('deposit_contracts/redeem', this.amountToRedeem);
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("redeem error:",err);
            redeemResult = false;
        }
        if (redeemResult) {
            this.$toast.success(`Successful redeeming`);
            this.amountToRedeem = this.amountToRemove = 0;
            this.onDepositInput();
            await this.initBalances();

        }
        this.redeeming = false;
        this.$store.commit('activeLoading',false)
    },
  },
}
</script>