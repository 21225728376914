<template>
    <div class="stakecontainer">
        <div class="stakehead">
            <div class="stakeico"><svg-injector :class-name="'js-svg-injector'" src="/img/icostake.svg"></svg-injector></div>
            <div>Stake {{pair}}</div>
        </div>
        <div class="enreach-panel stakepanel">
            <div class="stakelabel">
                <!-- <div class="slico"><svg-injector :class-name="'js-svg-injector'" src="/img/enrinsig.svg"></svg-injector></div> -->
                <div class="slico"><TokenIcon :token="token0 || 'NRCH'" /></div>
                <div>{{token0}}</div>
                <div class="rowarrows"><svg-injector :class-name="'js-svg-injector'" src="/img/rowarrows.svg"></svg-injector></div>
                <div class="slico"><TokenIcon :token="token1 || 'BNB'" /></div>
                <div>{{token1}}</div>
            </div>
            <div class="lplabel">LIQUIDITY POOL</div>
            <div class="stakegrid" v-if="isUserConnected && getLpInfos">
                <div>BALANCE:</div>
                <div class="sgv">
                    {{ this.$filters.formatTokenBalance(getNum, getLpInfos?.symbol, getLpInfos?.decimals) }}
                    <small class="priceFiat">{{ LpToFiat(getNum) }}</small>
                </div>
                <div v-if="getWlpInfos">Yaggr Token:</div>
                <div class="sgv" v-if="getWlpInfos">
                    {{ this.$filters.formatTokenBalance(newValue, getWlpInfos?.symbol,getWlpInfos?.decimals) }}
                    <small class="priceFiat">{{ LpToFiat(newValue) }}</small>
                </div>
                <div v-if="getQuoteBoost > 0">Boost <small>({{boostDeposit.toFixed(0)}}% {{getTokenInfos?.symbol}})</small>:<div v-tippy="{ content: 'This is the amount of NRCH user needs to boost yield. These tokens are returned to the user after the locking period.', theme: 'yaggr', arrow: false, placement: 'bottom-end' }"><svg-injector :w-class-name="'infoIcon'" :class-name="'js-svg-injector align-super'" src="/img/info.svg"></svg-injector></div></div>
                <div class="sgv" v-if="getQuoteBoost > 0">
                    {{ this.$filters.formatTokenBalance(quoteBoost, getTokenInfos?.symbol, getTokenInfos?.decimals) }}
                    <small class="priceFiat">{{ toFiat(quoteBoost, getTokenInfos?.symbol, getTokenInfos?.decimals) }}</small>
                </div>
                <div v-if="getQuoteUnStakeBoost > 0">BoostYieldFee:</div>
                <div class="sgv" v-if="getQuoteUnStakeBoost > 0">
                    {{ this.$filters.formatTokenBalance(quoteUnStakeBoost, 'BNB', 18) }}
                    <small class="priceFiat">{{ toFiat(quoteUnStakeBoost, 'BNB', 18) }}</small>
                </div>
                <div v-if="quoteYield > 0">Yield:<div v-tippy="{ content: 'This reward can be claimed at '+boostReward.toFixed(0)+'% discount to the market value after the locking period.', theme: 'yaggr', arrow: false, placement: 'bottom-end' }"><svg-injector :w-class-name="'infoIcon'" :class-name="'js-svg-injector align-super'" src="/img/info.svg"></svg-injector></div></div>
                <div class="sgv" v-if="quoteYield > 0">
                    {{ this.$filters.formatTokenBalance(quoteYield, getTokenInfos?.symbol, getTokenInfos?.decimals) }}
                    <small class="priceFiat">{{ toFiat(quoteYield, getTokenInfos?.symbol, getTokenInfos?.decimals) }}</small>
                </div>
            </div>
            <div v-if="(isUserConnected && getQuoteBoost) || true">Your {{getTokenInfos?.symbol}} balance is {{this.$filters.formatTokenBalance(getTokenBalance, getTokenInfos?.symbol, getTokenInfos?.decimals) }}</div>
            <div v-if="(isUserConnected && getQuoteUnStakeBoost > 0) || true">Your BNB balance is {{this.$filters.formatTokenBalance(getActiveBalanceWei, 'BNB', 18)}}</div>
            <ul class="nav nav-tabs mt-4">
                <li class="nav-item"><span class="nav-link simul-link" @click="tools=false" v-bind:class="{ active: !tools }">Actions</span></li>
                <li class="nav-item ms-4"><span class="nav-link simul-link" @click="tools=true" v-bind:class="{ active: tools }">Tools</span></li>
            </ul>
            <div class="fade show row p-4" v-if="tools">
                <div class="sbuts mb-2 mt-2" v-if="getQuoteUnStakeBoost > 0">
                    <p>If your {{getWlpInfos?.symbol}} got liquidated, you can claim your {{getTokenInfos?.symbol}} boosting deposit here!</p>
                    <button class="sapprove" :disabled="unBoostApproved" @click="approveUnBoost">
                        Approve
                    </button>
                    <svg-injector :w-class-name="'colArrow'" :class-name="'js-svg-injector align-middle'" src="/img/arrow-right.svg"></svg-injector>
                    <button class="sapprove" @click="claimYourDeposit" :disabled="!unBoostApproved">
                        <div>Claim Your Deposit</div>
                    </button>
                </div>
                <div class="col addtometa">
                    <p>
                        Add tokens to your metamask:<br />
                        <span class="simul-link" @click="this.$filters.addToMetaMask(getTokenInfos)"> Add {{getTokenInfos?.symbol}} Token </span><br />
                        <span class="simul-link" @click="this.$filters.addToMetaMask(poolInfos)"> Add {{poolInfos?.symbol}} Token</span><br />
                        <span class="simul-link" @click="this.$filters.addToMetaMask(getWlpInfos)"> Add {{getWlpInfos?.symbol}} Token </span>
                    </p>
                </div>
            </div>
            <div class="fade show pt-4" v-if="!tools">
                <p style="margin-top: -1rem;" v-if="displayForm && !getQuoteUnStakeBoost">LP stakers earn additional 1% of {{getTokenInfos?.symbol}} volume.</p>
                <div v-if="displayForm && !getQuoteUnStakeBoost" class="form_bg p-4 mx-auto" >
                    <div class="sbuts">
                        <input v-model.number="amountToMigrate" type="number" :autofocus="true" :disabled="approving || staking" @input="onStakeInput" placeholder="Amount" expanded class="sapprove" />
                        <button :disabled="approving || staking" class="sapprove" @click="setMax()" >
                            Max
                        </button>
                    </div>
                    <div class="sbuts mb-2 mt-2">
                        <button class="sapprove" :disabled="!(!approved || !isAmountToStakeValid())" @click="approve">
                            <div class="saicon"><svg-injector :class-name="'js-svg-injector'" src="/img/sapprove.svg"></svg-injector></div>
                            <div>APPROVE CONTRACT</div>
                        </button>
                        <svg-injector :w-class-name="'colArrow'" :class-name="'js-svg-injector align-middle'" src="/img/arrow-right.svg"></svg-injector>
                        <button class="sapprove" :disabled="!(approved && isAmountToStakeValid())" @click="stake">
                            <div>STAKE</div>
                        </button>
                    </div>
                </div>
                <div class="p-4 mb-2 mx-auto" v-if="(displayActions || displayForm) && !displayTimerBoost">
                    <div class="sbuts mb-2 mt-2" v-if="getQuoteBoost || displayForm">
                        <button class="sapprove" v-if="getQuoteBoost" :disabled="boostApproved" @click="approveBoost">
                            APPROVE BOOST
                        </button>
                        <svg-injector v-if="getQuoteBoost" :w-class-name="'colArrow'" :class-name="'js-svg-injector align-middle'" src="/img/arrow-right.svg"></svg-injector>
                        <button class="sapprove" v-if="!getQuoteUnStakeBoost" :disabled="!boostApproved" @click="boost">
                            BOOST YIELD 
                            <div v-tippy="{ content: 'After this action you <u>WON\'T</u> be able to STAKE or BOOST any more tokens until locking period is over.', theme: 'yaggr', arrow: false, placement: 'bottom-end' }"><svg-injector :w-class-name="'warningSign'" :class-name="'js-svg-injector align-top'" src="/img/warning.svg"></svg-injector></div>
                        </button>
                    </div>
                </div>
                <div class="p-4 mx-auto" v-if="displayActions">
                    <div class="sbuts mb-2">
                        <button class="sapprove" @click="unstake" v-if="getQuoteBoost > 0 || (getWlpInfos && !getQuoteUnStakeBoost && getWlpBalance > 0)">
                            <div>REDEEM</div>
                        </button>
                        <div v-else-if="displayTimerBoost">
                            <p>
                                You can't unstake boost before: {{displayTimerBoost.toLocaleDateString("en-GB")}} at {{displayTimerBoost.toLocaleTimeString("en-GB")}}<br />
                                <!-- You boosted: {{displayTimerBoost.timer.toLocaleDateString("en-GB")}} at {{displayTimerBoost.timer.toLocaleTimeString("en-GB")}} -->
                            </p>
                        </div>
                        <button class="sapprove" v-if="getQuoteUnStakeBoost > 0 && !displayTimerBoost" :disabled="unBoostApproved" @click="approveUnBoost">
                            APPROVE UNSTAKE BOOST
                        </button>
                        <svg-injector v-if="getQuoteUnStakeBoost > 0 && !displayTimerBoost" :w-class-name="'colArrow'" :class-name="'js-svg-injector align-middle'" src="/img/arrow-right.svg"></svg-injector>
                        <button class="sapprove" v-if="getQuoteUnStakeBoost > 0 && !displayTimerBoost" :disabled="!unBoostApproved" @click="unstakeBoost">
                            UNSTAKE BOOST 
                        </button>
                    </div>
                </div>
                <div class="row"  v-else-if="(getNum == 0 && newValue == 0 && this.poolInfos?.url)" >
                    <div class="col">
                        <p>To acquire the <strong>{{this.poolInfos?.symbol}} tokens</strong> of this pair <strong>{{pair}}</strong>,  please click here:</p>
                        <a :href="this.poolInfos?.url" target="_blank" class="stakebut">
                            Add Liquidity 
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.nav-link.disabled {
    color: #ffffff60;
    pointer-events: none;
}
    .nav-link{
        font-weight: 500;
        padding: 0 0 .75rem 0;
        position: relative;
        border: none;
        color: #ffffffd0;
        font-size: 1.2em;
    }
    .nav-link.active::after{
        width: 100%;
        height: 3px;
        background: #37ecba;
        position: absolute;
        content: "";
        bottom: 0px;
        left: 0;
    }
    .nav-tabs .nav-link:not(.active):hover {
        border: none;
        color: #FFF;
    }
    .nav-tabs .nav-link:not(.active):hover::after {
        width: 100%;
        height: 3px;
        background: #37ecba80;
        position: absolute;
        content: "";
        bottom: -1px;
        left: 0;
    }
    .nav-tabs .nav-link.active {
        color: #ffffff;
    }
</style>
<script>
import { utils } from "web3";
// import { utils as utils_eth } from "ethers";
import { mapGetters } from "vuex";
import axios from 'axios';
import TokenIcon from "@/components/TokenIcon";

export default {
  name: 'FarmsContainer',
  components: {
    TokenIcon
  },
  props:{
    pair: {
      type: String,
      required: true,
    },
    master_page: {
      type: String,
      required: true,
    }
  },
  data() {
      return {
        balanceTimeout: null,
        unstaking: false,
        token0: null,
        token1: null,
        Farm: null,
        poolInfos: {},
        amountToMigrate: 0,
        amountToStake: 0,
        contract: null,
        approving: false,
        staking: false,
        tools: false,
        priceInfo: null,
        listPriceIds:{
            "BNB":"binancecoin",
            "WBNB":"binancecoin",
            "NRCH":"enreachdao",
            "TST":"standard-token",
            "CAKE":"pancakeswap-token",
            "SLP":"sushi"
        }
    }
  },
  computed: {
    ...mapGetters(['getAddresses','getMasterInfos']),
    ...mapGetters("accounts", ["isUserConnected", "getActiveBalanceWei", "getChainId", "getWeb3"]),
    ...mapGetters("farms", ["getTokenInfos","getLpInfos","getWlpInfos","getWlpBalance", "getTokenBalance","getNum","getStaked","getApprovedBalance","getDeposit","getApprovedBoost","getApprovedUnBoost","getQuoteBoost","getQuoteUnStakeBoost","getStakedBoostTime", "getRewardCost", "getPoolDetails", "getLpReserves","getLpTotalSupply"]),
    displayForm(){
        return (this.getNum && this.getNum > 0);
    },
    quoteBoost(){
        return utils.toBN(this.getQuoteBoost);
    },
    boostDeposit(){
        return (((this.getPoolDetails?.boostDepositNum / this.getPoolDetails?.boostDepositDen)*100)/2);
    },
    boostReward(){
        return ((this.getPoolDetails?.boostRewardNum / this.getPoolDetails?.boostRewardDen)*100);
    },
    quoteYield(){
        let qYield = this.boostReward && this.getDeposit ? Math.round(this.getDeposit * Number(this.boostReward)/100)  : 0;
        return utils.toBN(qYield);
    },
    quoteUnStakeBoost(){
        return utils.toBN(this.getQuoteUnStakeBoost);
    },
    boostApproved(){
        return (this.getApprovedBoost && this.getApprovedBoost >= this.getQuoteBoost);
    },
    approved(){
        return (this.amountToStake 
          && this.amountToStake > 0
          && this.approvedBalance().gte(this.amountToStake));
    },
    unBoostApproved(){
        return (this.getApprovedUnBoost && this.getApprovedUnBoost > 0);
    },
    displayActions(){
        return ((this.newValue > 0 || this.getWlpBalance > 0 ) && (this.getQuoteUnStakeBoost || this.getQuoteBoost) )
    },
    displayTimerBoost(){
        const boostTime = Number(this.getStakedBoostTime) || 0;
        // let endDateDateTimeObj = (this.getStakedBoostTime.timer) ? new Date(1000*(Number(this.getStakedBoostTime.timer) + Number(this.getStakedBoostTime.boostDuration))):0
        const endDateDateTimeObj = new Date(1000*boostTime)
        if(boostTime == 0 || Date.now() > endDateDateTimeObj){
            return false;
        }
        return endDateDateTimeObj;
        // return {boostDuration: endDateDateTimeObj, timer: new Date(1000*Number(this.getStakedBoostTime.timer))}
    },
    displayRates(){
        return (this.priceInfo)?true:false;
    },
    newValue(){
        return this.$filters.getNewValue(this.getWlpBalance, this.amountToStake)
    }
  },
  mounted() {
    this.$store.commit('activeLoading',true);
    this.token0 = this.getMasterInfos[this.master_page][this.pair].names[0];
    this.token1 = this.getMasterInfos[this.master_page][this.pair].names[1];
    this.Farm = this.getMasterInfos[this.master_page][this.pair];
    if (!this.getWeb3) {
        document.location.href="/";
    } else {
        this.initContracts();
    }
  },
  beforeUnmount() {
    clearInterval(this.balanceTimeout);
    this.unstaking=false;
    this.amountToMigrate=0;
    this.amountToStake=0;
    this.contract=null;
    this.approving=false;
    this.staking=false;
    this.token0 = null;
    this.token1 = null;
    this.Farm = null;
    // this.approved=null;
    this.priceInfo=null;
  },
  methods: {
    initRate(ids=[]){
        axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${ids.join(',')},enreachdao,binancecoin&vs_currencies=usd,eur&t=${Math.round(new Date().getTime()/10**5)}`,
        { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0', } })
        .then(response => {
            this.priceInfo = response['data']
        })
        .catch(err => {
            console.log(err);
            throw err;
        })
    },
    toFiat (value,symbol,decimals, cur='usd') {
        let id = this.listPriceIds[symbol]
        if(!id || !this.priceInfo || !this.priceInfo[id])return 0;
        let rate = (value/10**decimals) * this.priceInfo[id][cur];
        return cur==="usd"?`$${(rate).toFixed(2)}`:`${(rate).toFixed(2)} ${cur}`;
    },
    LpToFiat(value, cur="usd"){
        // {{ getLpReserves }}- {{ getLpTotalSupply }}
        // const reserves = [ { "address": "0x69fa8e7F6bf1ca1fB0de61e1366f7412b827CC51", "name": "EnreachDAO", "symbol": "NRCH", "decimals": "9", "reserve": "25986863060960" }, { "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", "name": "Wrapped BNB", "symbol": "WBNB", "decimals": "18", "reserve": "237003371249568882050" } ]
        // const totalSupply = 75334728781665892
        const reserves = this.getLpReserves;
        const totalSupply = this.getLpTotalSupply;
        const rates = [];
        reserves?.forEach((item)=>{
            item.reserve = Number(item.reserve)
            item.decimals = Number(item.decimals)
            let id = this.listPriceIds[item.symbol]
            if(!id || !this.priceInfo || !this.priceInfo[id])return 0;
            rates.push((item.reserve/10**item.decimals) * this.priceInfo[id][cur]);
        })
        // Sum of rates * value / totalSupply
        let rate = rates.reduce((a,b)=>a+b,0) * value / totalSupply;
        return cur==="usd"?`$${(rate).toFixed(2)}`:`${(rate).toFixed(2)} ${cur}`;
    },
    async approve(){
        this.$store.commit('activeLoading',true)
        this.approving = true;
        // let approveResult;
        try {
            await this.$store.dispatch('farms/approve', this.amountToStake)
            .then(()=>{
                this.$store.dispatch('farms/getApprovedBalanceForUser');
                this.$toast.success(`Successful approving`);
            });
        }
        catch(err) {
            this.$toast.error(`approval error`);
            console.log(err);
        }
        this.$store.commit('activeLoading',false)
        this.approving = false;
        
    },
    async approveBoost(){
        if(this.getTokenBalance < this.getQuoteBoost){
            this.$toast.error(`Insufficient funds! You need ${this.$filters.formatTokenBalance(this.quoteBoost, this.getTokenInfos?.symbol, this.getTokenInfos?.decimals)}`)
            return false;
        }
        this.$store.commit('activeLoading',true)
        try {
            // TODO: à finir avec getApprovedBoost
            await this.$store.dispatch('farms/approveBoost', this.amountToStake)
            .then(()=>{
                this.$store.dispatch('farms/getApprovedBoostForUser');
                this.$toast.success(`Successful approving`);
            });
        }
        catch(err) {
            this.$toast.error(`approval error`);
            console.log(err);
        }
        this.$store.commit('activeLoading',false)
    },
    async approveUnBoost(){
        if(this.getWlpInfos && this.getWlpBalance == 0 && this.getWlpBalance < this.getStaked){
            this.$toast.error(`Insufficient funds! You need ${this.$filters.formatTokenBalance(this.getStaked, this.getWlpInfos?.symbol, this.getWlpInfos?.decimals)}`)
            return false;
        }
        if(this.getActiveBalanceWei < this.getQuoteUnStakeBoost){
            this.$toast.error(`Insufficient funds! You need ${this.$filters.formatTokenBalance(this.quoteUnStakeBoost, 'BNB', 18)}`)
            return false;
        }
        this.$store.commit('activeLoading',true)
        try {
            // TODO: à finir avec getApprovedBoost
            await this.$store.dispatch('farms/approveUnBoost')
            .then(()=>{
                this.$store.dispatch('farms/getApprovedUnBoostForUser');
                this.$toast.success(`Successful approving`);
            });
        }
        catch(err) {
            this.$toast.error(`approval error`);
            console.log(err);
        }
        this.$store.commit('activeLoading',false)
    },
    async initContracts() {
        if(this.getChainId){
            console.log("init Pool:",this.pair);
            // let Farm = this.getMasterInfos[this.master_page][this.pair];
            let Token = this.Farm["token"]
            let tokenAddress = this.getAddresses[Token][this.getChainId];
            this.$store.dispatch("farms/storeTokenAddress",tokenAddress);

            let Staking = this.Farm["contract"]
            let lpAddress = this.getAddresses[Staking][this.getChainId];
            this.$store.dispatch("farms/storeLpAddress", lpAddress);

            let Pool = this.Farm["pool"]
            this.poolInfos = this.getAddresses[Pool][this.getChainId];
            this.$store.dispatch("farms/storePoolAddress",this.poolInfos);


            let wlpTkn = this.Farm["wlptoken"] ? this.Farm["wlptoken"] : `wlp${this.pair}`;
            let wlpAddress = this.getAddresses[wlpTkn]? this.getAddresses[wlpTkn][this.getChainId] : null;
            await this.$store.dispatch("farms/storeWlpAddress", wlpAddress);
            await this.$store.dispatch("farms/fetchPoolContract");
            await this.$store.dispatch("farms/fetchLpContract");
            await this.$store.dispatch('farms/fetchTokenContract');
            await this.$store.dispatch("farms/fetchWlpContract");
            await this.$store.dispatch("farms/fetchPoolDetails");
            await this.$store.dispatch("farms/fetchRewardCost");
            await this.$store.dispatch("farms/fetchLpReserves");
            await this.$store.dispatch("farms/fetchLpTotalSupply");
            
            this.initRate([this.listPriceIds[this.token0], this.listPriceIds[this.token1],this.listPriceIds[this.poolInfos['symbol']]]);
            await this.initBalances()//.then(this.$toast.success(`Contracts are initialized`));
            // return this.getChainId;

            this.balanceTimeout = setInterval(async () => {
                await this.$store.dispatch("farms/fetchTokenBalance");
                await this.$store.dispatch("farms/fetchWlpBalance");
                await this.$store.dispatch("farms/fetchPool");
                await this.$store.dispatch('farms/getApprovedBalanceForUser');
                await this.$store.dispatch('farms/getApprovedBoostForUser');
                await this.$store.dispatch('farms/checkDeposit');
                await this.$store.dispatch('farms/getApprovedUnBoostForUser');
            }, 5000);
            this.$store.commit('activeLoading',false)
        }else{
            // TODO: FIX this loop
            setTimeout(() => {
                this.initContracts();
            }, 500);
        }
    },
    async initBalances(){
        await this.$store.dispatch("farms/fetchTokenBalance");
        await this.$store.dispatch("farms/fetchPool");
        this.$store.dispatch('farms/fetchQuoteBoost');
        await this.$store.dispatch('farms/getApprovedBalanceForUser');
        await this.$store.dispatch('farms/getApprovedBoostForUser');
        await this.$store.dispatch("farms/fetchWlpBalance");
        await this.$store.dispatch('farms/checkDeposit');
        await this.$store.dispatch('farms/getApprovedUnBoostForUser');
        this.setMax();
    },
    approvedBalance() {
        try {
          return utils.toBN(this.getApprovedBalance);
        }
        catch {
            return utils.toBN("0")
        }
    },
    setMax() {
        // ignore if max is pressed twice
        if (utils.isBigNumber(this.amountToStake) && this.getNum.eq(this.amountToStake)) {
            return;
        }
        this.amountToStake = this.getNum;
        this.amountToMigrate = this.$filters.format(this.amountToStake, this.getWlpInfos?.decimals, null);
        this.onStakeInput()
    },
    inputToBigNumber(value) {
        try { 
            var validInput = utils.toWei(String(value || 0),'ether')    
            return utils.toBN(validInput) ;
        }
        catch (err) {
            console.log(err); 
            return null;
        }
    },
    onStakeInput() {
        this.amountToStake = this.inputToBigNumber(this.amountToMigrate);
        // this.approved = false;
        if(this.getNum > 0 &&  this.amountToStake.gt(utils.toBN(this.getNum)) ){
            this.setMax();
            return false;
        }
        // if (
        //   this.amountToStake 
        //   && this.amountToStake > 0
        //   && this.approvedBalance().gte(this.amountToStake)
        // ) {
        //   // Account has on-chain approval
        //   this.approved = true;
        // }
        // else {
        //   // No on-chain approval
        //   this.approved = false;
        // }
    },
    isAmountToStakeValid() {
        try {
            return this.amountToStake && this.amountToStake > 0 && this.amountToStake.lte(utils.toBN(this.getNum));
        }
        catch(err) {
            console.error(err)
            return false;
        }
    },
    isAmountToUnstakeValid() {
        return true
        // try {
        //     return this.amountToUnstake &&  this.amountToUnstake > 0 && this.amountToUnstake.lte(this.getStaked);
        // }
        // catch {
        //     return false;
        // }
    },
    async stake() {
        this.staking = true;
        this.$store.commit('activeLoading',true)
        let stakeResult;
        try {
            stakeResult = await this.$store.dispatch('farms/getDeposit', this.amountToStake);
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("stake error:",err);
            stakeResult = false;
        }
        if (stakeResult) {
            this.$toast.success(`Successful staking`);
            this.amountToStake = this.amountToMigrate = 0;
            this.onStakeInput();
            await this.initBalances();
        }
        this.staking = false;
        this.$store.commit('activeLoading',false)
    },
    async unstake() {
        if(this.getWlpInfos && this.getWlpBalance < 0){
            this.$toast.error(`Insufficient funds! You need ${this.$filters.formatTokenBalance(this.getStaked, this.getWlpInfos?.symbol, this.getWlpInfos?.decimals)}`)
            return false;
        }
        this.unstaking = true;
        this.$store.commit('activeLoading',true)
        let unstakeResult;
        try {
            unstakeResult = await this.$store.dispatch('farms/unstake');
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("stake error:",err);
            unstakeResult = false;
        }
        if (unstakeResult) {
            this.$toast.success(`Successful unstaking`);
            // this.amountToUnstake = this.amountToRemove = 0;
            this.onStakeInput();
            await this.initBalances();

        }
        this.unstaking = false;
        this.$store.commit('activeLoading',false)
    },
    async unstakeBoost() {
        if(this.getWlpInfos && this.getWlpBalance < this.getStaked){
            this.$toast.error(`Insufficient funds! You need ${this.$filters.formatTokenBalance(this.getStaked, this.getWlpInfos?.symbol, this.getWlpInfos?.decimals)}`)
            return false;
        }
        if(this.getActiveBalanceWei < this.getQuoteUnStakeBoost){
            this.$toast.error(`Insufficient funds! You need ${this.$filters.formatTokenBalance(this.quoteUnStakeBoost, 'BNB', 18)}`)
            return false;
        }
        this.unstaking = true;
        this.$store.commit('activeLoading',true)
        let unstakeResult;
        try {
            unstakeResult = await this.$store.dispatch('farms/unstakeBoost');
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("stake error:",err);
            unstakeResult = false;
        }
        console.log("unstakeboost unstakeResult:",unstakeResult);
        if (unstakeResult?.booster) {
            this.$toast.success(`Successful unstaking`);
            // this.amountToUnstake = this.amountToRemove = 0;
            this.onStakeInput();
            await this.initBalances();
            this.$toast.success(`Congratulation! You receive ${this.$filters.formatTokenBalance(unstakeResult?.reward,this.getTokenInfos?.symbol, this.getTokenInfos?.decimals)} of Reward,\n
             ${this.$filters.formatTokenBalance(unstakeResult?.booster,this.getTokenInfos?.symbol, this.getTokenInfos?.decimals)} of Boost`)
        }
        this.unstaking = false;
        this.$store.commit('activeLoading',false)
    },
    async claimYourDeposit() {
        this.$store.commit('activeLoading',true)
        let claimResult;
        try {
            claimResult = await this.$store.dispatch('farms/claimDeposit');
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("claim error:",err);
            claimResult = false;
        }
        console.log("claimYourDeposit claimResult:",claimResult);
        if (claimResult?.deposit) {
            this.$toast.success(`Successful claim`);
            // this.amountToUnstake = this.amountToRemove = 0;
            this.onStakeInput();
            await this.initBalances();
            this.$toast.success(`Congratulation! You have recovered ${this.$filters.formatTokenBalance(claimResult?.deposit,this.getTokenInfos?.symbol, this.getTokenInfos?.decimals)}!`)
        }else{
            this.$toast.error(`An error occured, Something wrong!`);
        }
        this.$store.commit('activeLoading',false)
    },
    async boost() {
        if(this.getTokenBalance < this.getQuoteBoost){
            this.$toast.error(`Insufficient funds! You need ${this.$filters.formatTokenBalance(this.quoteBoost, this.getTokenInfos?.symbol, this.getTokenInfos?.decimals)}`)
            return false;
        }
        // this.unstaking = true;
        this.$store.commit('activeLoading',true)
        let boostResult;
        try {
            boostResult = await this.$store.dispatch('farms/boost');
        }
        catch (err) {
            this.$toast.error(`An error occured`);
            console.log("boost error:",err);
            boostResult = false;
        }
        console.log("boostResult:",boostResult);
        if(boostResult?.message){
            this.$toast.error(boostResult.message);
        }else if (boostResult) {
            this.$toast.success(`Successful boost`);
            // this.amountToUnstake = this.amountToRemove = 0;
            this.onStakeInput();
            await this.initBalances();

        }
        // this.unstaking = false;
        this.$store.commit('activeLoading',false)
    },
  },
}
</script>