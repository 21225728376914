<template>
    <div class="enreach-cardhead">
        <div class="icon"><svg-injector :class-name="'js-svg-injector'" src="/img/brackets.svg"></svg-injector></div>
        <div class="text">Pairs</div>
        <div class="enreach-panel">
          <div class="enreach-table strategiespage" v-if="pools.length > 0">
            <div class="thead">
              <div class="struct">
                <div>PAIR</div>
                <div>Informations</div>
              </div>
            </div>
            <div class="tbody">
              <div class="struct">
                <div class="pairList">
                    <StrategyItem :v-if="loading==false" v-for="(pool, idx) in pools" :index="index" :key="pool.id" :master_page="master_page" :pool="pool" @click="selectPair(idx)" />
                </div>
                <div class="pairInfos">
                  <div class="encart">
                    <StrategyDetails :pool="pools[index]" :master_page="master_page" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text" v-else-if="loading==false">Coming Soon</div>
          <div class="text" v-else>Loading</div>
        </div>
    </div>
</template>
<script>
import StrategyItem from "@/components/StrategyItem.vue";
import StrategyDetails from "@/components/StrategyDetails.vue";
import { mapGetters } from "vuex";
export default {
  name: 'StrategiesPage',
  props:{
    master_page: {
      type: String,
      required: true,
    //   default: 'farms',
    }
  },
  data(){
      return {
        index: 0,
        pools: [],
        loading: true
      }
  },
  watch: {
    master_page (val) {
      const _pools = this.getMasterInfos[val]
      this.cleanLoop(_pools)
    }
  },
  computed: {
    ...mapGetters(['getAddresses','getMasterInfos']),
  },
  created() {
    const _pools = this.getMasterInfos[this.master_page] || []
    this.cleanLoop(_pools);
  },
  methods: {
    async cleanLoop(_pools){
        let result = [];
        let idx=0;
        for(let id in _pools){
          let pool = _pools[id];
          const contract = pool?.contract;
          const pool_key = pool?.pool;
          const chain_id = pool?.activeChain;
          if(contract in this.getAddresses && pool_key in this.getAddresses){
            pool.pool_address = this.getAddresses[pool_key][chain_id]['address'];
          }
          pool.id = id;
          pool.idx = idx++;
          result.push(pool);
        }
        this.loading=false;
        this.pools=result;
    },
    selectPair(index){
      this.index = index;
    },
  },
  components: {
    StrategyItem,
    StrategyDetails
  },
}
</script>