let network = process.env.NODE_ENV === "production" ? "0x38" : "0x61";
network = "0x38";
export default {
    tokens:{
        NRCH:{names:["NRCH","ygNRCH"],token:"NRCH",vetoken:"ygNRCH",activeChain:network},
    },
    ecosystem:{
        NRCHWBNB:{names:["NRCH","WBNB"],pool:"LPPool",token:"Token",contract:"LPStaking",activeChain:network},
        NRCHBUSD:{names:["NRCH","USDT"]},
        ygNRCHNRCH:{names:["ygNRCH","NRCH"]},
    },
    blackmagic:{
        ygVOODOOVOODOO:{names:["ygVOODOO","VOODOO"],activeChain:network},
        ygCIM:{names:["ygCIM","CIM"],activeChain:network},
    },
    nreserve:{
    },
    nineNine:{
        YAGGRcnEUR:{names:["YAGGR","cnEUR"],activeChain:network},
        YAGGRcnUSD:{names:["YAGGR","cnUSD"],activeChain:network},
        CIMcnEUR:{names:["CIM","cnEUR"],activeChain:network},
        CIMcnUSD:{names:["CIM","cnUSD"],activeChain:network},
    },
    permission:{
        
    },
    dashboard:{
        ygNRCH:{names:"ygNRCH", activeChain:network, token:"NRCH", ygtoken:"ygNRCH", oneIcon:"rowcoinsolo"},
    }
}
