<template>
    <div class="enreach-cardhead">
        <div class="icon"><svg-injector :class-name="'js-svg-injector'" src="/img/singleToken.svg"></svg-injector></div>
        <div class="text">Single Sided</div>
        <div class="enreach-panel">
            <div class="enreach-table ssfarmstable">
                <div class="thead">
                    <div class="enreach-row">
                        <div>TOKEN</div>
                        <div>vTOKEN</div>
                        <div>YIELD</div>
                    </div>
                </div>
                <div class="tbody">
                    <SingleItem v-for="(token, index) in tokens" :index=index :key="token.id" :token="token" />
                </div>
            </div>
        </div>
    </div>
    
</template>
<script>
import SingleItem from "@/components/SingleItem.vue";
import { mapGetters } from 'vuex';
export default {
    name: 'SingleSidedFarms',
    data(){
        return {
            tokens: []
        }
    },
    computed: {
        ...mapGetters(['getMasterInfos']),
    },
    created() {
        const _tokens = this.getMasterInfos['tokens'] || []
        this.cleanLoop(_tokens);
    },
    methods: {
        cleanLoop(_tokens){
            let result = [];
            for(let id in _tokens){
                let token = _tokens[id];
                token.id = id;
                result.push(token)
            }
            this.tokens=result;
        },
    },
    components: {
        SingleItem,
    },
}
</script>
