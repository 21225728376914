import LPStaking from "../../contracts/LPStaking.json";
import uniswapPair from "../../interfaces/IUniswapV2Pair.json";
import TokenWLP from "../../contracts/YG4626C.json";
// import Web3 from "web3";

const state = {
  num: 0,
  staked: 0,
  approvedBalance: 0,
  approvedBoost: 0,
  approvedUnBoost: 0,
  quoteBoost: 0,
  quoteUnStakeBoost: 0,
  stakedBoostTime: {},
  tokenBalance: null,
  tokenAddress: null,
  tokenContract: null,
  tokenInfos: null,
  wlpAddress: null,
  wlpContract: null,
  wlpInfos: null,
  wlpBalance: 0,
  lpAddress: null,
  lpContract: null,
  lpInfos: null,
  lpReserves: null,
  lpTotalSupply: null,
  poolAddress: null,
  poolId: null,
  poolContract: null,
  poolDetails: {},
  rewardCost: 0,
  stakeWithPermit: null,
  lpStakingApy: null,
  lpBoostApy: null,
  lpBalance: null,
  poolTotal: null,
  deposit: null
};

const getters = {
  getNum: (state) => state.num,
  getStaked: (state) => state.staked,
  getTokenBalance: (state) => state.tokenBalance,
  getTokenAddress: (state) => state.tokenAddress,
  getTokenInfos: (state) => state.tokenInfos,
  getLpAddress: (state) => state.lpAddress,
  getLpContract: (state) => state.lpContract,
  getLpInfos: (state) => state.lpInfos,
  getWlpInfos: (state) => state.wlpInfos,
  getWlpBalance: (state) => state.wlpBalance,
  getPoolAddress: (state) => state.poolAddress,
  getPoolId: (state) => state.poolId,
  getPoolDetails: (state) => state.poolDetails,
  getPoolContract: (state) => state.poolContract,
  getApprovedBalance: (state) => Number(state.approvedBalance),
  getApprovedBoost: (state) => Number(state.approvedBoost),
  getApprovedUnBoost: (state) => Number(state.approvedUnBoost),
  getQuoteBoost: (state) => Number(state.quoteBoost),
  getQuoteUnStakeBoost: (state) => Number(state.quoteUnStakeBoost),
  getStakedBoostTime: (state) => state.stakedBoostTime,
  getStakeWithPermit: (state) => state.stakeWithPermit,
  getLpStakingAPY: (state) => state.lpStakingApy,
  getLpBoostAPY: (state) => state.lpBoostApy,
  getLpBalance: (state) => state.lpBalance,
  getLpReserves: (state) => state.lpReserves,
  getLpTotalSupply: (state) => state.lpTotalSupply,
  getPoolTotal: (state) => state.poolTotal,
  getRewardCost: (state) => state.rewardCost,
  getDeposit: (state) => state.deposit
};

const actions = {
  async fetchTokenContract({ commit, rootState }) {
    const web3 = rootState.accounts.web3;
    const address = rootState.farms.tokenAddress;
    const contract = new web3.eth.Contract(rootState.tokenERC20Abi, address);
    commit("setTokenContract", contract);
    const name = await contract.methods.name().call();
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    commit("setTokenInfos",{"type":"ERC20","address":address,"name":name,"symbol":symbol,"decimals":decimals})
  },
  async fetchLpContract({ commit, rootState }) {
    const web3 = rootState.accounts.web3;
    const address = rootState.farms.lpAddress;
    const contract = new web3.eth.Contract(LPStaking.abi, address);
    commit("setLpContract", contract);
    commit("setLpInfos",{"type":"ERC20","address":address,"symbol":"SLP","decimals":18})
  },
  async fetchWlpContract({ commit, rootState }) {
    const web3 = rootState.accounts.web3;
    const address = rootState.farms.wlpAddress;
    let wlpInfos = null;
    let contract = null;
    if(address){
      contract = new web3.eth.Contract(TokenWLP.abi, address);
      const name = await contract.methods.name().call();
      const symbol = await contract.methods.symbol().call();
      const decimals = await contract.methods.decimals().call();
      wlpInfos = {"type":"ERC20","address":address,"name":name,"symbol":symbol,"decimals":decimals}
    }
    commit("setWlpContract", contract);
    commit("setWlpInfos",wlpInfos)
  },
  // staked(lpContract, userAddress)
  async fetchStaked({ commit, rootState }) {
    try{
      await rootState.farms.lpContract.methods.staked(rootState.farms.poolId, rootState.accounts.activeAccount).call().then((res) => {
        if(res.lpStaked){commit("setStaked", res.lpStaked);}
      });
    }catch{
      commit("setStaked", 0);
    }
  },
  async fetchPoolContract({ commit, rootState }){
    let web3 = rootState.accounts.web3;
    let contract = new web3.eth.Contract(uniswapPair.abi, rootState.farms.poolAddress);
    commit("setPoolContract", contract);
  },
  async fetchPool({ commit, rootState }) {
    await rootState.farms.poolContract.methods.balanceOf(rootState.accounts.activeAccount).call().then(num => {
      commit("setNum", num);
    });
  },
  async fetchTokenBalance({ commit, rootState }) {
    await rootState.farms.tokenContract.methods.balanceOf(rootState.accounts.activeAccount).call().then(num => {
      commit("setTokenBalance", num);
    });
  },
  async fetchWlpBalance({ commit, rootState }) {
    if(rootState.farms.wlpContract){
      await rootState.farms.wlpContract.methods.assetsOf(rootState.accounts.activeAccount).call().then(num => {
        commit("setWlpBalance", num);
      });
    }else{
      commit("setWlpBalance", 0)
    }
  },
  fetchQuoteBoost({ dispatch, commit, rootState }) {
    rootState.farms.lpContract.methods.quoteBoost(rootState.farms.poolAddress)
    .call({from: rootState.accounts.activeAccount}, (error, num) => {
      if(!error){
        commit("setQuoteBoost", num);
        commit("setQuoteUnStakeBoost", 0);
        commit("setStakedBoostTime", {});
      }else{
        let msg = error.message ? error.message : "An other error";
        if(msg.match("you can't boost multiple times")){
          commit("setQuoteBoost", 0);
          dispatch("fetchQuoteUnStakeBoost")
        }
      }
    });
  },
  fetchQuoteUnStakeBoost({ dispatch, commit, rootState }) {
    rootState.farms.lpContract.methods.quoteUnStakeBoost(rootState.farms.poolAddress)
      .call({from: rootState.accounts.activeAccount}, (error, num) => {
      if(!error){
        console.log("fetchQuoteUnStakeBoost:",num);
        commit("setQuoteUnStakeBoost", num);
        dispatch("fetchStakedBoostTime");
      }else{
        console.log("fetchQuoteUnStakeBoost error:",error);
        // let msg = error.message ? error.message : "An other error";
        commit("setQuoteUnStakeBoost", 0);
        commit("setStakedBoostTime", {});
      }
    });
  },
  fetchStakedBoostTime({ commit, rootState }) {
    if (rootState.farms.wlpContract && rootState.accounts.activeAccount) {
      rootState.farms.wlpContract.methods.lockedTimestamp(rootState.accounts.activeAccount)
        .call({from: rootState.accounts.activeAccount}, (error, res) => {
        if(!error){
          commit("setStakedBoostTime", res);
        }else{
          console.log("fetchStakedBoostTime error:",error);
          // let msg = error.message ? error.message : "An other error";
          commit("setStakedBoostTime", {});
        }
      });
    }
  },
  fetchLpBalance({ commit, rootState }) {
    rootState.farms.poolContract.methods.balanceOf(rootState.farms.lpAddress)
      .call({}, (error, res) => {
      if(!error){
        console.log("fetchLpBalance:",res);
        commit("setLpBalance", res);
      }else{
        console.log("setLpBalance error:",error);
        // let msg = error.message ? error.message : "An other error";
        commit("setLpBalance", 0);
      }
    });
  },
  async fetchLpReserves({commit, rootState }){
    const reserves = await rootState.farms.poolContract.methods.getReserves().call();
    const tokens = [];
    tokens[0] = await rootState.farms.poolContract.methods.token0().call();
    tokens[1] = await rootState.farms.poolContract.methods.token1().call();
    const web3 = rootState.accounts.web3;
    let infos = [];
    tokens.forEach(async address => {
      const contract = new web3.eth.Contract(TokenWLP.abi, address);
      const name = await contract.methods.name().call();
      const symbol = await contract.methods.symbol().call();
      const decimals = await contract.methods.decimals().call();
      infos.push({"address":address,"name":name,"symbol":symbol,"decimals":decimals,"reserve":reserves[tokens.indexOf(address)]})
    });
    commit("setLpReserves", infos);
  },
  fetchLpTotalSupply({commit, rootState }){
    rootState.farms.poolContract.methods.totalSupply()
    .call({},(error, res) => {
      if(!error){
        console.log("fetchLpTotalSupply:",res);
        commit("setLpTotalSupply", res);
      }else{
        console.log("fetchLpTotalSupply error:",error);
        // let msg = error.message ? error.message : "An other error";
        commit("setLpTotalSupply", 0);
      }
    });
  },
  fetchPoolTotal({ commit, rootState }) {
    rootState.farms.poolContract.methods.totalSupply()
      .call({}, (error, res) => {
      if(!error){
        console.log("fetchPoolTotal:",res);
        commit("setPoolTotal", res);
      }else{
        console.log("setPoolTotal error:",error);
        // let msg = error.message ? error.message : "An other error";
        commit("setPoolTotal", 0);
      }
    });
  },
  async fetchRewardCost({ commit, rootState }) {
    await rootState.farms.lpContract.methods.rewardCost(rootState.farms.poolId).call().then(cost => {
      commit("setRewardCost", ((cost.boostCostNum/cost.boostCostDen)*100).toFixed(2));
    });
  },
  async fetchPoolDetails({ commit, rootState }) {
    await rootState.farms.lpContract.methods.pools(rootState.farms.poolId).call().then(details => {
      commit("setPoolDetails", details);
    });
  },
  async fetchLpApy({ commit, rootState }) {
    if (!rootState.farms.lpContract) {
      this.fetchLpContract();
    }
    if (!rootState.farms.poolAddress) {
      this.storePoolAddress();
    }
    await rootState.farms.lpContract.methods.stakingAPY(rootState.farms.poolAddress).call({}, (error, res) => {
      if(!error){
        // let apy = Number(res[0]);
        // let precision = Number(res[1]);
        // let duration = (Number(res[2])/86400)*365;

        commit("setLpStakingApy", Math.round((res[0]/res[1]/res[2])));
      }else{
        console.log("StakingAPY error:",error);
      }
    });
    await rootState.farms.lpContract.methods.boostAPY(rootState.farms.poolAddress).call({}, (error, res) => {
      if(!error){
        commit("setLpBoostApy", (res[0]/res[1])*100);
      }else{
        console.log("BoostAPY error:",error);
      }
    });
  },
  storeTokenAddress({ commit },tokenAddress) {
    // let chainIdDec = rootState.accounts.chainId;
    // let tokenAddress = addresses.Token[chainIdDec];
    console.log("storeTokenAddress:",tokenAddress);
    commit("setTokenAddress", tokenAddress);
  },
  storeLpAddress({ commit }, lpAddress) {
    commit("setLpAddress", lpAddress);
  },
  storeWlpAddress({ commit }, wlpAddress) {
    commit("setWlpAddress", wlpAddress);
  },
  storePoolAddress({ commit },poolInfos) {
    commit("setPoolAddress", poolInfos['address']);
    commit("setPoolId", poolInfos['id']);
  },
  async approve({ rootState }, stakeAmount) {
    if (rootState.farms && rootState.accounts.activeAccount) {
      // const lpContract = rootState.farms.lpContract;
      const poolContract = rootState.farms.poolContract;

      try {
        await poolContract.methods.approve(rootState.farms.wlpAddress, stakeAmount).send({from: rootState.accounts.activeAccount});
        return true;
      }
      catch (err) {
        console.log("signature error:",err);
        return false;
      }
    }
  },
  async getApprovedBalanceForUser({rootState, commit}) {
    if (rootState.farms.poolContract && rootState.accounts.activeAccount) {
      await rootState.farms.poolContract.methods.allowance(rootState.accounts.activeAccount, rootState.farms.wlpAddress).call().then(num => {
        commit('setApprovedBalance', num);
      });
    }
  },
  async getApprovedBoostForUser({rootState, commit}) {
    if (rootState.farms.tokenContract && rootState.accounts.activeAccount) {
      await rootState.farms.tokenContract.methods.allowance(rootState.accounts.activeAccount, rootState.farms.lpAddress).call().then(num => {
        commit('setApprovedBoost', num);
      });
    }
  },
  async getApprovedUnBoostForUser({rootState, commit}) {
    if (rootState.farms.wlpContract && rootState.accounts.activeAccount) {
      await rootState.farms.wlpContract.methods.allowance(rootState.accounts.activeAccount, rootState.farms.lpAddress).call().then(num => {
        commit('setApprovedUnBoost', num);
      });
    }
  },
  async getDeposit({rootState}, depositAmount) {
    if(rootState.farms.approvedBalance < depositAmount) return false;
    if (rootState.farms.wlpContract && rootState.accounts.activeAccount) {
      const wlpContract = rootState.farms.wlpContract;
      const user = rootState.accounts.activeAccount
        // Handle stake
      try {
        await wlpContract.methods.deposit(depositAmount, user).send({from: user});
        // await tx.wait(1);
        // updated approved amount?
        return true;
      }
      catch (err) {
        console.log('stake:',err)
        return false;
      }
    }
  },
  async checkDeposit({rootState, commit}) {
    if (rootState.farms.lpContract && rootState.accounts.activeAccount) {
      const lpContract = rootState.farms.lpContract;
      // Handle stake
      try {
        if(rootState.farms.quoteBoost > 0){
          commit('setDeposit', rootState.farms.quoteBoost)
        }else{
          await lpContract.methods.claimDeposit(rootState.farms.poolAddress).call({from: rootState.accounts.activeAccount}).then((claim) => {
            if(claim && claim.deposit){
              commit('setDeposit', claim.deposit);
            }else{
              commit('setDeposit', 0);
            }
          }).catch(()=>{
            commit('setDeposit', 0);
          });

        }
      }
      catch (err) {
        // console.log('checkdeposit error:',err)
        return false;
      }
    }
  },
  async claimDeposit({rootState}) {
    if (rootState.farms.lpContract && rootState.accounts.activeAccount) {
      const lpContract = rootState.farms.lpContract;
      // Handle stake
      try {
        let claim = await lpContract.methods.claimDeposit(rootState.farms.poolAddress).call({from: rootState.accounts.activeAccount});
        // await tx.wait(1);
        // updated approved amount?
        await lpContract.methods.claimDeposit(rootState.farms.poolAddress).send({from: rootState.accounts.activeAccount});
        return claim;
      }
      catch (err) {
        console.log('claimdeposit error:',err)
        return false;
      }
    }
  },
  async unstake({rootState}) {
    if (rootState.farms.wlpContract && rootState.accounts.activeAccount) {
      const wlpContract = rootState.farms.wlpContract;
      const user = rootState.accounts.activeAccount
      // Handle stake
      try {
        await wlpContract.methods.redeem(rootState.farms.wlpBalance,user,user).send({from: user});
        // await tx.wait(1);
        // updated approved amount?
        return true;
      }
      catch (err) {
        console.log('unstake error:',err)
        return false;
      }
    }
  },
  async unstakeBoost({rootState}) {
    if (rootState.farms.lpContract && rootState.accounts.activeAccount) {
      const lpContract = rootState.farms.lpContract;
      // Handle stake
      try {
        let token = await lpContract.methods.unStakeBoost(rootState.farms.poolAddress).call({from: rootState.accounts.activeAccount, value: rootState.farms.quoteUnStakeBoost});
        // await tx.wait(1);
        // updated approved amount?
        await lpContract.methods.unStakeBoost(rootState.farms.poolAddress).send({from: rootState.accounts.activeAccount, value: rootState.farms.quoteUnStakeBoost}).then((res,err)=>{
          console.log('unstakeBoost err:',err);
          console.log('unstakeBoost res:',res);
          return token;
        }).catch((err)=>{
          console.log('unstakeBoost err catched:', err)
          return false;
        });
        // console.log('tx executed:',tx);
        return token;
      }
      catch (err) {
        console.log('unstakeBoost error:',err)
        return false;
      }
    }
  },
  async approveUnBoost({rootState}) {
    if (rootState.farms.wlpContract && rootState.accounts.activeAccount && rootState.farms.wlpBalance > 0) {
      const wlpContract = rootState.farms.wlpContract;
      // const lpContract = rootState.farms.lpContract;
      try {
        // approve 10% more in case of slippage
        let wlpBalance = rootState.farms.wlpBalance
        console.log("wlpBalanceAmount:",wlpBalance);
        await wlpContract.methods.approve(rootState.farms.lpAddress, wlpBalance).send({from: rootState.accounts.activeAccount}).then((err,res)=>{
          console.log(err,res);
          // await lpContract.methods.boost(rootState.farms.poolAddress).send({from: rootState.accounts.activeAccount});
        })
        // await tx.wait(1);
        // updated approved amount?
        return true;
      }
      catch (err) {
        console.log('boost:',err)
        return false;
      }
    }
  },
  async approveBoost({rootState}) {
    if (rootState.farms.tokenContract && rootState.accounts.activeAccount && rootState.farms.quoteBoost > 0) {
      const tokenContract = rootState.farms.tokenContract;
      // const lpContract = rootState.farms.lpContract;
      try {
        // approve 10% more in case of slippage
        let quoteBoostAmount = rootState.farms.quoteBoost
        await tokenContract.methods.approve(rootState.farms.lpAddress, quoteBoostAmount).send({from: rootState.accounts.activeAccount}).then((err,res)=>{
          console.log(err,res);
          // await lpContract.methods.boost(rootState.farms.poolAddress).send({from: rootState.accounts.activeAccount});
        })
        // await tx.wait(1);
        // updated approved amount?
        return true;
      }
      catch (err) {
        console.log('boost:',err)
        return false;
      }
    }
  },
  async boost({rootState}) {
    if (rootState.farms.lpContract && rootState.accounts.activeAccount) {
      const lpContract = rootState.farms.lpContract;
      // const lpContract = rootState.farms.lpContract;
      try {
        const check = await lpContract.methods.boost(rootState.farms.poolAddress).call({from: rootState.accounts.activeAccount}).catch((err)=>{
          console.log('catch err:', err.message)
          return err;
        });
        if(check?.message && check.message.indexOf("The Smart Contract is out of reward token notify Yaggr team")>=0){
          return {error:true,message:"The Smart Contract is out of reward token notify Yaggr team"};
        }
        const tx = await lpContract.methods.boost(rootState.farms.poolAddress).send({from: rootState.accounts.activeAccount}).then((res,err)=>{
          console.log('boost err:',err);
          console.log('boost res:',res);
          return res;
        }).catch((err)=>{
          console.log('catch err:', err)
          return err;
        });
        // await tx.wait(1);
        // updated approved amount?
        return tx;
      }
      catch (err) {
        console.log('boost:', err)
        return false;
      }
    }
  },
};

const mutations = {
  setNum(state, _num) {
    state.num = _num;
  },
  setStaked(state, _staked) {
    state.staked = _staked;
  },
  setTokenBalance(state, _num) {
    state.tokenBalance = _num;
  },
  setTokenAddress(state, _address) {
    state.tokenAddress = _address;
  },
  setTokenContract(state, _contract) {
    state.tokenContract = _contract;
  },
  setTokenInfos(state, _infos) {
    state.tokenInfos = _infos;
  },
  setWlpAddress(state, _address) {
    state.wlpAddress = _address;
  },
  setWlpInfos(state, _infos) {
    state.wlpInfos = _infos;
  },
  setWlpContract(state, _contract) {
    state.wlpContract = _contract;
  },
  setLpAddress(state, address) {
    state.lpAddress = address;
  },
  setLpContract(state, _contract) {
    state.lpContract = _contract;
  },
  setLpInfos(state, _infos) {
    state.lpInfos = _infos;
  },
  setWlpBalance(state, _num) {
    state.wlpBalance = _num;
  },
  setQuoteBoost(state, _quoteBoost) {
    state.quoteBoost = _quoteBoost;
  },
  setQuoteUnStakeBoost(state, _quoteUnStakeBoost) {
    state.quoteUnStakeBoost = _quoteUnStakeBoost;
  },
  setStakedBoostTime(state, _stakedBoostTime) {
    state.stakedBoostTime = _stakedBoostTime;
  },
  setPoolAddress(state, address) {
    state.poolAddress = address;
  },
  setPoolId(state, id) {
    state.poolId = id;
  },
  setPoolDetails(state, details) {
    state.poolDetails = details;
  },
  setPoolContract(state, _contract) {
    state.poolContract = _contract;
  },
  setApprovedBoost(state, approvedBoost) {
    state.approvedBoost = approvedBoost;
  },
  setApprovedUnBoost(state, approvedUnBoost) {
    state.approvedUnBoost = approvedUnBoost;
  },
  setApprovedBalance(state, approvedBalance) {
    state.approvedBalance = approvedBalance;
  },
  setStakeWithPermit(state, stakeWithPermit) {
    state.stakeWithPermit = stakeWithPermit;
  },
  setLpStakingApy(state, _num) {
    state.lpStakingApy = _num;
  },
  setLpBoostApy(state, _num) {
    state.lpBoostApy = _num;
  },
  setLpBalance(state, _num) {
    state.lpBalance = _num;
  },
  setLpReserves(state, _reserves) {
    state.lpReserves = _reserves;
  },
  setLpTotalSupply(state, _totalSupply) {
    state.lpTotalSupply = _totalSupply;
  },
  setPoolTotal(state, _num) {
    state.poolTotal = _num;
  },
  setRewardCost(state, _num) {
    state.rewardCost = _num;
  },
  setDeposit(state, _num) {
    state.deposit = _num;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};