import { createApp } from 'vue'
import App from './App.vue'
// import './util/msgs'
import router from './router'
import store from './store'
// import SVGInjectorVue from 'svginjector-vue'
import SvgInjector from './components/svg-injector.vue'
import { utils } from "web3";

// ... considering that your app creation is here
import Toaster from '@meforma/vue-toaster'

// import Toasted from 'vue-toasted'

// Vue.use(Toasted,{
//     iconPack: 'fontawesome',
//     duration: 3000
// })

// Vue.toasted.register(
//     'defaultSuccess',
//     payload => !payload.msg ? 'Operation successful!' : payload.msg,
//     {type: 'success', icon: 'check'}
// )

// Vue.toasted.register(
//     'defaultError',
//     payload => !payload.msg ? 'Oops... Unexpected error.' : payload.msg,
//     {type: 'error', icon : 'times'}
// )
// app.config.globalProperties.$filters = {
//   currencyUSD(value) {
//     return '$' + value
//   }
// }
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

import './registerServiceWorker'
const app = createApp(App)
    // .use(SVGInjectorVue)
    .use(Toaster, {
        // One of the options
        position: 'top'
      })
    .use(VueCustomTooltip, {
      name: 'VueCustomTooltip',
      color: '#000',
      background: '#FFFFFF',
      borderRadius: 5,
      fontWeight: 500,
    })
    .use(
      VueTippy,
      // optional
      {
        directive: 'tippy', // => v-tippy
        component: 'tippy', // => <tippy/>
        componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
        defaultProps: {
          placement: 'auto-end',
          allowHTML: true,
        }, // => Global default options * see all props
      }
    )
    .use(router)
    .use(store);
app.component(SvgInjector.name, SvgInjector);

const decimalToUnit = (value) => {
  let mapping = utils.unitMap;
  let decimals = Number(value);
  let unitMapValue = String(10**decimals);
   
  for (const key in mapping) {
    if (mapping[key] === unitMapValue) {
      return key;
    }
  }
  return null;
}
const icons = {
  "wMULTInEUR":"multi",
  "wMULTInUSD":"multi",
  "wsGYRO":"gyro",
  "veGYRO":"gyro",
  "YAGGR":"nrch",
  "ygNRCH":{name:"ygNRCH",ext:"png"},
  "EPX":{name:"eps",ext:"png"},
  "ygCIM":{name:"cim",ext:"png"},
  "CIM":{name:"cim",ext:"png"},
  // "cnEUR":{name:"eur",ext:"png"},
  // "nUSD":{name:"usd",ext:"png"},
  // "cnUSD":{name:"usd",ext:"png"},
  // "ygBUSDOTC":{name:"otc",ext:"png"},
  "sVOODOO":"voodoo",
  "ygVOODOO":"voodoo",
  "ONE":"stakedao"
}
app.config.globalProperties.$filters = {
  fromWei(value, decimals=18) {
    if (!value) return value;
    return parseFloat(value) / 1**Number(decimals);
  },
  decimalToUnit(value){
    return decimalToUnit(value);
  },
  async addToMetaMask(token) {
    console.log("addToMetaMask:",token);
    if (token?.address) {
      const { protocol, host } = window.location
      const image = `${protocol}//${host}${this.tokenImage(token?.symbol)}`
      try {
        // `${protocol}//${host}/tokens/${token?.symbol}.png`
        await window.ethereum?.request({
          method: "wallet_watchAsset",
          params: {
            type: token?.type || "ERC20",
            options: {
              address: token.address,
              symbol: token?.symbol,
              decimals: token?.decimals,
              image: image
            }
          }
        });
      }
      catch (err) {
        app.$toast.info(`An error has occurred! Try to add it manually:<br />
         <ul>
          <li>address: ${token?.address}</li>
          <li>symbol: ${token?.symbol}</li>
          <li>decimals: ${token?.decimals}</li>
          <li>image: ${image}</li>
         </ul>`,{
          duration: 5000,
          pauseOnHover: true,
          dismissible: false

        });
      }
    }
  },
  formatTokenBalance(value, symbol, decimals=18) {
      try {
        value=String(value);
        value = Number(utils.fromWei(value, decimalToUnit(decimals)));
        return `${value.toLocaleString("en-US",{style:'decimal',maximumFractionDigits:5})} ${symbol}`;
      }
      catch(err) {
          // console.log("formatTokenBalance error:",err);
          return `0`;
      }
  },
  format(value,decimals=18,locale="en-US",params={}) {
      try {
        value=String(value)
        const decimal = utils.fromWei(value, decimalToUnit(decimals))
        const formatted = decimal.replace(/[.]0*$/, '') // Drop trailing zeroes
        if(locale){
          return Number(formatted).toLocaleString(locale,params)
        }else{
          return formatted
        }
      }
      catch(err) {
          return null;
      }
  },
  getNewValue(currentValue, amount, addition=true) {
    let newValue;
    if (utils.isBigNumber(currentValue) && utils.isBigNumber(amount)) {
        if (addition) {
            newValue = currentValue.add(amount);
        }
        else if (amount.lt(currentValue)) {
            newValue = currentValue.sub(amount);
        }
    }
    else {
        newValue = currentValue;
    }
    return newValue;
  },
  tokenImage(token){
    let imgName = icons[token] || token.toLowerCase();
    return `/img/${imgName?.name || imgName}.${imgName?.ext || 'svg'}`;
  }
}

app.mount('#app');
