<template>
  <SideMenu :pages=pages :activePage=isActivePage />
  <div class="enreach-content pb-5">
    <div class="enreach-header">
      <div class="toplogo moblogo"></div>
      <div class="enreach-details">
      <div v-if="nrchTotalSupply > 0">
        <div class="statgrid">
          <div class="staticon">
            <div class="icon">
              <svg-injector :class-name="'js-svg-injector'" src="/img/statrotate.svg"></svg-injector>
            </div>
          </div>
          <div>
            <div class="stath">Circulating Supply</div>
            <span class="sgv">{{new Intl.NumberFormat('en-US', { }).format(nrchTotalSupply)}} <span class="fs14">NRCH</span></span>
          </div>
        </div>
      </div>
      <div v-if="NRCHTotalStaked > 0">
        <div class="statgrid">
          <div class="staticon">
            <div class="icon">
              <svg-injector :class-name="'js-svg-injector'" src="/img/statcoins.svg"></svg-injector>
            </div>
          </div>
          <div>
            <div class="stath">Total Staked</div>
            <span class="sgv">{{new Intl.NumberFormat('en-US').format(NRCHTotalStaked)}} <span class="fs14">NRCH</span></span>
            <div class="stath stathsec">{{new Intl.NumberFormat('en-US').format(ygNrchTotalSupply)}} <span class="fs14">ygNRCH</span></div>
          </div>
        </div>
      </div>
      <div v-if="NRCHTotalStaked > 0 && this.nrchMarkets?.current_price">
        <div class="statgrid">
          <div class="staticon">
            <div class="icon">
              <svg-injector :class-name="'js-svg-injector'" src="/img/statlock.svg"></svg-injector>
            </div>
          </div>
          <div>
            <div class="stath">TVL</div>
            <span class="sgv">{{nrchToFiat(NRCHTotalStaked)}}</span>
          </div>
        </div>
      </div>
      <div v-if="nrchTotalSupply > 0">
        <div class="statgrid">
          <div class="staticon">
            <div class="icon">
              <svg-injector :class-name="'js-svg-injector'" src="/img/statpie.svg"></svg-injector>
            </div>
          </div>
          <div>
            <div class="stath">Locked</div>
            <span class="sgv">{{((NRCHTotalStaked/nrchTotalSupply)*100).toFixed(2)}}<span class="fs14"> %</span></span>
          </div>
        </div>
      </div>
    </div>
      <div class="hamburger" @click="this.$store.state.mobMenu=!this.$store.state.mobMenu" v-bind:class="{ hide: this.$store.state.mobMenu }"><svg-injector :class-name="'js-svg-injector'" src="/img/hamb.svg"></svg-injector></div>
      <div class="mobhider" @click="this.$store.state.mobMenu=!this.$store.state.mobMenu" v-if="this.$store.state.mobMenu"></div>
      <div class="mmico">
        <svg-injector :class-name="'js-svg-injector'" src="/img/mm.svg"></svg-injector>
      </div>
      <div class="walldata" v-tippy="{ content: (isUserConnected?'<span class=\'fw600 nrcyan fs14\'>Click to disconnect</span><br/><span class=\'fw500 fs14\'>'+currentNetwork+'</span><br/><span class=\'fs13\'>'+getActiveAccount.substring(0, 8)+'....'+getActiveAccount.substr(-6)+'</span>':'<span class=\'fw500 fs14\'>Connect to Metamask</span>'), theme: 'yaggr', arrow: false, placement: 'bottom-end' }" :class="'switch '+(isUserConnected ? 'on' : 'off')">
        <!-- <div class="connectedchain" v-if="!isUserConnected" @click="connectWeb3Modal">
            <div class="address">Connected to Metamask</div>
            <div class="icon"><svg-injector :class-name="'js-svg-injector'" src="/img/mm.svg"></svg-injector></div>
        </div>
        <div class="connectedwallet" v-if="isUserConnected" @click="disconnectWeb3Modal">
            <div class="address">{{ currentNetwork }} / Disconnect {{getActiveAccount.substring(0, 7)}}...</div>
            <div class="icon"><svg-injector :class-name="'js-svg-injector'" src="/img/wallet.svg"></svg-injector></div>
        </div> -->
        <!-- Switch button on/off -->
            <div class="content" v-if="isUserConnected" @click="disconnectWeb3Modal"><div class="connected">ON</div></div>
            <div class="content" v-if="!isUserConnected" @click="connectWeb3Modal"><div class="disconnected">OFF</div></div>
            <div class="icon"><svg-injector :class-name="'js-svg-injector'" src="/img/nrchswitch.svg"></svg-injector></div>
      </div>
      
    </div>
    <div class="enreach-pageheading"  v-if="!isStakePage">
        {{pages[isActivePage].name}}
    </div>
    <div class="enreach-card">
      <router-view v-if="(!isCheckNetwork || getChainId===theActiveChain) && ( ygTknBalance >= ygTknNeedIt)"></router-view>
      <div class="enreach-panel mt-5 pb-4" v-else-if="!(!isCheckNetwork || getChainId===theActiveChain)">
        <h4 class="">
          <span class="alerticon" style="display:inline-block;width:1em"><svg-injector :class-name="'js-svg-injector'" src="/img/alert.svg"></svg-injector></span>&nbsp;&nbsp;<span style="line-height:24px;vertical-align: top;">Network error</span>
        </h4>
        <p class="block-title mt-4">Your Metamask current network is <strong>{{ currentNetwork }}</strong></p>
        <p class="card-title">Connect your Metamask network to <strong>{{theActiveChainName}}</strong> </p>
        <span class="card-link text-white fw-bold simul-link" @click="connectWeb3Modal" v-if="!isUserConnected">Connected to Metamask</span>
        <span class="card-link fw-bold simul-link alert-action mt-4" @click="changeNetwork" v-else>Click here</span>
      </div>
      <div class="card text-center border-white bg-transparent text-white col-md-6 mx-auto mt-5 pb-4" v-else>
        <h5 class="card-header border-white bg-transparent text-danger">
          Insufficient {{ygTknSymbol}}
        </h5>
        <p class="block-title mt-4">You need to have at least <strong>{{getYgTknMin}} {{ygTknSymbol}}</strong> to continue</p>
        <p class="card-title">You have <strong>{{ygTknBalance}} {{ygTknSymbol}}</strong> </p>
        <span class="card-link text-white fw-bold simul-link" @click="this.$router.push({ name: 'single', params: { single: getYgTknNeed?.replace('yg','') } })">Click here</span>
      </div>
        
    </div>
  </div>
  <div class="loading" v-if="isLoading">
    <spinner></spinner>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SideMenu from './components/SideMenu.vue';
import Spinner from "@/components/Spinner";
import axios from "axios";

export default {
  name: 'App',
  components: {
    SideMenu,
    Spinner
  },
  data () {
    return {
      pages: [
        {name: 'Single Token Strategies', className: 'p-ssf', img: '/img/singleToken.svg', link: '/'},
        {name: 'Ecosystem Strategies', className: 'p-esf', img: '/img/ecosystemfarms.svg', link: '/ecosystem'},
        {name: 'nReserve', className: 'p-bdi', img: '/img/bsc.svg', link: '/nreserve'},
        {name: 'Governance', className: 'p-gs', img: '/img/enr.svg', link: '/governance-staking'},
        {name: 'NRCH Traders Ranking', className: 'p-gs', img: '/img/rank.svg', link: '/trades'},
        // {name: 'My Dashboard', className: 'p-dash', img: '/img/dashboard.svg', link: '/dashboard'},
      ],
      initApiTimeout: null,
      nrchAPI: {},
      nrchMarkets: null,
      // activePage: 0,
      // // stakePage: false,
    }
  },
  computed: {
    ...mapGetters(["getYgTknBalance", "getYgTknInfos", "getYgTknNeed","getYgTknMin","getNrchAPI","getAddresses","getMasterInfos"]),
    ...mapGetters("accounts", ["getActiveAccount", "isUserConnected", "getWeb3Modal","getChains","getChainId"]),
    currentNetwork() {
      if(!this.isUserConnected) return 'Offline'
      const activeNewtork = this.getChains[this.getChainId];
      return activeNewtork?.name || 'Undefined network';
    },
    currentNetworkShort() {
      if(!this.isUserConnected) return 'Offline'
      const activeNewtork = this.getChains[this.getChainId];
      return (activeNewtork?.shortName)?`${activeNewtork?.shortName?.toUpperCase()} chain`: 'Undefined network';
    },
    isStakePage(){
      return this.$store.state.stakePage;
    },
    isActivePage(){
      return this.$store.state.activePage;
    },
    isLoading(){
      return this.$store.state.loading;
    },
    isCheckNetwork(){
      return this.$store.state.checkNetwork;
    },
    theActiveChain(){
      return this.$store.state.accounts.activeChain;
    },
    theActiveChainName(){
      return this.$store.state.accounts.activeChainName || "Undefined";
    },
    nrchTotalSupply(){
      return this.nrchMarkets?.circulating_supply || 0;
      // return totalSupply > 0 ? totalSupply - (Number(this.getNrchAPI?.ygNRCH?.bsc?.totalSupply)/ 10 ** Number(this.getNrchAPI?.ygNRCH?.bsc?.decimals)): 0;
      // return `${totalSupply.toFixed(2)} NRCH`;
    },
    ygNrchTotalSupply(){
      const totalSupply =  this.getNrchAPI?.ygNRCH?.bsc?.totalSupply || 0;
      const decimals =  this.getNrchAPI?.ygNRCH?.bsc?.decimals || 9;
      return totalSupply / 10 ** decimals;
    },
    NRCHTotalStaked(){
      const totalAssets =  this.getNrchAPI?.ygNRCH?.bsc?.totalAssets || 0;
      const decimals =  this.getNrchAPI?.ygNRCH?.bsc?.decimals || 9;
      return totalAssets / 10 ** decimals;
    },
    ygTknBalance(){
      return this.getYgTknBalance;
    },
    ygTknNeedIt(){
      const minValue = this.getYgTknMin || 0;
      const decimals = this.getYgTknInfos?.decimals || 9;
      return minValue * (10 ** decimals);
    },
    ygTknSymbol(){
      return this.getYgTknInfos?.symbol || "ygNRCH";
    },
  },
  mounted() {
    this.initNrchRate();
    this.initAPI();

    this.initApiTimeout = setInterval(async () => {
      this.initNrchRate();
      this.initAPI();
      this.fetchYgTknBalance();
    }, 45000);
  },
  beforeUnmount() {
    clearInterval(this.initApiTimeout);
  },
  async created() {
    await this.$store.dispatch("accounts/initWeb3Modal");
    await this.$store.dispatch("accounts/ethereumListener");
    this.fetchData();
    this.fetchYgTknContract();
    this.fetchYgTknBalance();
  },
  watch: {
    // appeler encore la méthode si la route change
    '$route': 'fetchData',
    nrchMarkets(val){
      if(val){
        this.$store.commit("setNrchPrice", val)
      }
    },
  },
  methods: {
    async initNrchRate(){
        await axios.get(`https://api.coingecko.com/api/v3/coins/markets?ids=enreachdao&vs_currency=usd&t=${Math.round(new Date().getTime()/10**5)}`,
        { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0', } })
        .then(response => {
            this.nrchMarkets = response['data'][0]
        })
        .catch(err => {
            console.log(err);
            throw err;
        })
    },
    nrchToFiat (value,decimals=0) {
        if(!this.nrchMarkets?.current_price)return 0;
        if(decimals > 0){
          const rate = (value/10**decimals) * this.nrchMarkets['current_price'];
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rate);
        }else{
          const rate = value * this.nrchMarkets['current_price'];
          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rate);
        }
    },
    async initAPI(){
        await axios.get(`/static/nrchAPI.json?t=${Math.round(new Date().getTime()/10**5)}`,
        { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0', } })
        .then((response) => {
          this.$store.commit('setNrchAPI', response.data);
        })
        .catch(err => {
            console.log(err);
            throw err;
        })
    },
    fetchData (to) {
      if(typeof to !== 'undefined'){
        this.$store.commit('navigation',to.meta.page);
        this.$store.commit('stakeGo',to.meta.stakePage);
        this.$store.commit('setCheckNetwork',to.meta.checkNetwork);
        let pair = to?.params?.pair || to?.params?.single;
        let masterPage = to?.params?.master_page || to?.meta?.master_page || 'tokens';
        try{
          const pageInfos = (pair && this.getMasterInfos[masterPage][pair]) ?  this.getMasterInfos[masterPage][pair] : null;
          if(pageInfos){
            this.$store.commit('accounts/setActiveChain', pageInfos?.activeChain)
            // NRCHWBNB:{names:["NRCH","WBNB"],pool:"LPPool",token:"Token",contract:"LPStaking",activeChain:"0x61",ygTknNeed:"ygNRCH", ygTknMin:1},
          }
          this.$store.commit('setYgTknMin', pageInfos?.ygTknMin || 0)
          this.$store.commit('setYgTknNeed', pageInfos?.ygTknNeed)
          console.log('pageInfos',pageInfos);
        }catch(err){
          console.log("fetchData error:",err);
        }
      }
      this.fetchYgTknContract();
      this.fetchYgTknBalance();
      this.initAPI()
      this.loading = true
    },
    async changeNetwork(){
      this.$store.state.loading = true
      this.$toast.info('Check your Metamask');
      await this.setNetwork();
      this.$store.state.loading = false
      return
    },
    ...mapActions(["fetchYgTknContract","fetchYgTknBalance"]),
    ...mapActions("accounts", ["connectWeb3Modal", "disconnectWeb3Modal","switchNetwork","setNetwork"])
  }
}
</script>
<style>
  .walldata > div {
    cursor: pointer;
  }
  .loading{
    position: fixed;
    top: 0;bottom: 0;width: 100%;left: 0;right: 0;height: 100vh;
    background-color: rgba(55, 55, 55, 0.8);
  }
  .loading > div{
    position: fixed;
    top: 50%;
    width: 100%;
    font-size: 30px;
    text-align: center;
  }
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
.alert-action{
    cursor: pointer;
    display: inline-table;
    font-size: 18px;
    font-weight: 700;
    padding: 12px 24px;
    background: #37ecba;
    border-radius: 5px;
    font-family: 'Rajdhani', sans-serif;
    color: #0a228b;
    text-decoration: none;
}
.alerticon path.st1{
  fill: #37ecba;
}

.stathsec{
  opacity: .75;
  font-weight: 400;
  margin-top: 2px;
}
</style>
