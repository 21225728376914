import BuyToken from "../../contracts/buyToken.json";

const state = {
  num: 0,
  bought: 0,
  estimateGas: 0,
  tokenAddress: null,
  tokenContract: null,
  tokenInfos: null,
  buyTokenAddress: null,
  buyTokenContract: null,
  buyTokenInfos: null
};

const getters = {
  getNum: (state) => state.num,
  getBought: (state) => state.bought,
  getEstimateGas: (state) => state.estimateGas,
  getTokenAddress: (state) => state.tokenAddress,
  getTokenInfos: (state) => state.tokenInfos,
  getBuyTokenAddress: (state) => state.buyTokenAddress,
  getBuyTokenInfos: (state) => state.buyTokenInfos,
};

const actions = {
  async fetchTokenContract({ commit, rootState }) {
    const web3 = rootState.accounts.web3;
    const address = rootState.buy_contracts.tokenAddress;
    console.log('tokenERC20 address:',address);
    const contract = new web3.eth.Contract(rootState.tokenERC20Abi, address);
    commit("setTokenContract", contract);
    const name = await contract.methods.name().call();
    const symbol = await contract.methods.symbol().call();
    const decimals = await contract.methods.decimals().call();
    // symbol = symbol.replace("TST","NRCH");
    commit("setTokenInfos",{"type":"ERC20","address":address,"name":name,"symbol":symbol,"decimals":decimals})
  },
  async fetchBuyTokenContract({ commit, rootState }) {
    const web3 = rootState.accounts.web3;
    const address = rootState.buy_contracts.buyTokenAddress;
    const tokenAddress = rootState.buy_contracts.tokenAddress;
    const contract = new web3.eth.Contract(BuyToken.abi, address);
    commit("setBuyTokenContract", contract);
    // let pool_id = await contract.methods.pools_id(tokenAddress).call();
    // if(pool_id){
    //     // console.log('pool_id:',pool_id);
    //     let poolInfos = await contract.methods.pools(pool_id).call();
    //     poolInfos.pool_id=pool_id;
    //     // console.log('pool_info:',poolInfos);
    //     commit("setBuyTokenInfos",poolInfos);
    // }else{
    //     commit("setBuyTokenInfos",{});
    // }
    await contract.methods.pools_id(tokenAddress).call().then(pool_id => {
        contract.methods.pools(pool_id).call().then(infos=>{
            infos.pool_id=pool_id;
            commit("setBuyTokenInfos",infos);
        });
    }).catch((err)=>{
        commit("setBuyTokenInfos",{});
        console.log('catch err:', err);
        return err;
    });
  },
  fetchEstimateGas({commit}){
    // if (rootState.buy_contracts.buyTokenContract && rootState.accounts.activeAccount && rootState.accounts.activeBalance > 0) {
    //   const buyTokenContract = rootState.buy_contracts.buyTokenContract;
    //   try {
    //     await buyTokenContract.methods.buy(rootState.buy_contracts.tokenAddress).estimateGas({from: rootState.accounts.activeAccount, value: 1000000})
    //     .then((gasAmount) => {
    //         console.log("gasAmount:",gasAmount,(gasAmount*1.1));
    //         commit('setEstimateGas',(gasAmount*1.1));
    //     })
    //     .catch((error) => {
    //         console.log('buy estimateGas error:', error);
    //         commit('setEstimateGas',76000);
    //     });
    //     return true;
    //   }
    //   catch (err) {
    //     console.log('buy:',err)
    //     return false;
    //   }
    // }
    commit('setEstimateGas',76000);
  },
  async fetchBalance({ commit, rootState }) {
    await rootState.buy_contracts.tokenContract.methods.balanceOf(rootState.accounts.activeAccount).call().then((num) => {
      commit("setNum", num)
    });
  },
  async fetchBought({ commit, rootState }) {
    let pool_id = rootState.buy_contracts.buyTokenInfos?.pool_id
    if(pool_id){
        await rootState.buy_contracts.buyTokenContract.methods.bought(pool_id,rootState.accounts.activeAccount).call().then((res) => {
        console.log('fetchBought:', res);
        commit("setBought", res)
        });
    }
  },
  async getBuy({rootState}, depositAmount) {
    if (rootState.buy_contracts.buyTokenContract && rootState.accounts.activeAccount) {
      const buyTokenContract = rootState.buy_contracts.buyTokenContract;
      try {
        await buyTokenContract.methods.buy(rootState.buy_contracts.tokenAddress).send({from: rootState.accounts.activeAccount,value: depositAmount});
        return true;
      }
      catch (err) {
        console.log('buy:',err)
        return false;
      }
    }
  }
};

const mutations = {
  setNum(state, _num) {
    state.num = _num;
  },
  setTokenAddress(state, _address) {
    state.tokenAddress = _address;
  },
  setTokenContract(state, _contract) {
    state.tokenContract = _contract;
  },
  setTokenInfos(state, _infos) {
    state.tokenInfos = _infos;
  },
  setBuyTokenAddress(state, _address) {
    state.buyTokenAddress = _address;
  },
  setBuyTokenContract(state, _contract) {
    state.buyTokenContract = _contract;
  },
  setBuyTokenInfos(state, _infos) {
    state.buyTokenInfos = _infos;
  },
  setBought(state, _amount) {
    state.bought = _amount;
  },
  setEstimateGas(state, _amount) {
    state.estimateGas = _amount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};